import { axiosPrivate } from "../../../../common/axiosPrivate";
import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import ColumnFilter from "../../../../components/ColumnFilter";
import { toast } from "react-hot-toast";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { ModalSpinner } from "../../../../components";
import SimpleTable from "../../../../components/SimpleTable";
import { useForm } from "react-hook-form";
import ModalVerSolicitudVacaciones from "./ModalVerSolicitudVacaciones";
import {
  getPeriodosVacacionesDisponiblesPorTrabajador,
  getRegistrosVacacionesTrabajador,
  getSolicitudesVacacionesTrabajador,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../../../Redux/Actions";

export default function ModalSolicitudVacaciones({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipoPeriodo: "",
    },
  });

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [loadingModal, setLoadingModal] = useState(false);
  const [showModalVerSolicitudVacaciones, setShowModalVerSolicitudVacaciones] =
    useState(false);
  const [verSolicitudData, setVerSolicitudData] = useState([]);
  const [registrosVacaciones, setRegistrosVacaciones] = useState([]);

  const watchDataFechaInicio = watch("fechaInicio", "");

  const dispatch = useDispatch();

  const userId = localStorage.getItem("userId");

  const centroCostoId = localStorage.getItem("centroCostoId");
  const trabajadorId = modalData.trabajadorId;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingModal(false);
      await Promise.all([
        dispatch(getPeriodosVacacionesDisponiblesPorTrabajador(trabajadorId)),
        // dispatch(getRegistrosVacacionesTrabajador(trabajadorId)),
        dispatch(getSolicitudesVacacionesTrabajador(trabajadorId))
      ]);
      setLoadingModal(true);
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      setLoadingModal(true);
    });
  }, [refreshTable, dispatch]);

  const periodoVacacionesTrabajador = useSelector(
    (state) => state.infoPeriodosDisponiblesVacacionesTrabajador
  );

  const infoRegistrosVacaciones = useSelector(
    (state) => state.infoSolicitudesVacacionesTrabajador
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoPeriodosVacacionesTrabajador"));
      dispatch(limpiarInfoMenuEdicion("infoSolicitudesVacacionesTrabajador"));
    };
  }, []);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  useEffect(() => {
    if (modalData) {
      reset({
        id: modalData.trabajadorId,
        trabajador:
          modalData.nombres +
          " " +
          modalData.apellidoPaterno +
          " " +
          modalData.apellidoMaterno,
        rut: UtilsFunctions.rutFormatter(modalData.rut, { dots: true }),
        fechaIngreso: modalData.fechaIngresoLegal,
        tipoPeriodo: ""
      });
    }
  }, [dispatch]);

  const inputRef = useRef(null);

  const handleChangeDuracion = (e) => {
    let value = e.target.value;

    // Eliminar caracteres no numéricos excepto al inicio
    value = value.replace(/[^0-9]/g, '');

    // Actualizar el valor con la palabra "días" concatenada
    const formattedValue = value ? `${value} días` : "";

    setValue("duracion", formattedValue);

    // Colocar el cursor antes de la palabra "días"
    setTimeout(() => {
      const input = inputRef.current;
      if (input) {
        input.setSelectionRange(value.length, value.length);
      }
    }, 0);
  };

  useEffect(() => {
    const input = inputRef.current;
    const value = watch("duracion") || "";
    if (input) {
      const numericValue = value.replace(/[^0-9]/g, '');
      input.setSelectionRange(numericValue.length, numericValue.length);
    }
  }, [watch("duracion")]);

  async function solicitarPropuestaVacaciones(data) {
    toast.loading("Solicitando...", estilosAlerta);
    try {
      if(data.tipoPeriodo === "1"){
        try {
          const requestBody = {
            fechaInicio: data.fechaInicio,
            duracion: parseInt(data.duracion.slice(0, -5)),
            trabajadorId: trabajadorId,
            centroCostoId: centroCostoId,
          };
          const response = await axiosPrivate.post(
            `${api_service}/api/v1/vacaciones/registros/propuesta/duracion`,
            requestBody,
            Authorization
          );
            setRegistrosVacaciones([]);
            reset();
            setRegistrosVacaciones(response.data);
            toast.dismiss();
            toast.success("Datos cargados con éxito", estilosAlerta);
      } catch (error) {
        console.log(error);
        toast.dismiss();
        toast.error(error.response.data.message? error.response.data.message : "Error al solicitar Vacaciones", estilosAlerta);
        }
      } else if (data.tipoPeriodo === "2"){

        try {
        const requestBody = {
          fechaInicio: watch("fechaInicio"),
          fechaTermino: watch("fechaTermino"),
          trabajadorId: trabajadorId,
          centroCostoId: centroCostoId,
        };
        const response = await axiosPrivate.post(
          `${api_service}/api/v1/vacaciones/registros/propuesta`,
          requestBody,
          Authorization
        );
          setRegistrosVacaciones([]);
          reset();
          setRegistrosVacaciones(response.data);
          toast.dismiss();
          toast.success("Datos cargados con éxito", estilosAlerta);
        } catch (error) {
          console.log(error);
          toast.dismiss();
          toast.error(error.response.data.message? error.response.data.message : "Error al solicitar Vacaciones", estilosAlerta);
          }  
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar datos",
        estilosAlerta
      );
    }
  }

  async function confirmarCambios() {
    toast.loading("Guardando...", estilosAlerta);
    try {
      const formValues = getValues();

      const requestBody = registrosVacaciones.map((objeto, index) => ({
        anyoPeriodo: parseInt(formValues[`anyoPeriodo_${index}`].slice(0, 4)),
        fechaInicio: formValues[`fechaInicio_${index}`],
        fechaTermino: formValues[`fechaTermino_${index}`],
        diasHabiles: parseInt(formValues[`diasHabiles_${index}`]),
        diasFeriados: parseInt(formValues[`diasFeriados_${index}`]),
        tipoRegistro:
          formValues[`tipoRegistro_${index}`] === "Vacaciones Normales"
            ? "VACACIONES_NORMALES"
            : formValues[`tipoRegistro_${index}`] === "Vacaciones Progresivas"
            ? "VACACIONES_PROGRESIVAS"
            : formValues[`tipoRegistro_${index}`] ===
                "Vacaciones Proporcionales" && "VACACIONES_PROPORCIONALES",
        periodoVacacionId: objeto.periodoVacacionId,
      }));

      const response = await axiosPrivate.post(
        `${api_service}/api/v1/vacaciones/centro-costo/${centroCostoId}/registros/usuario/${userId}`,
        requestBody,
        Authorization
      );
      if (response.status === 200 && response.data.length > 0) {
        setValue("fechaInicio", "");
        setValue("fechaTermino", "");
        setValue("duracion", "");
        setRegistrosVacaciones([]);
        toast.dismiss();
        toast.success("Datos guardados con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
      } else if (response.status !== 200 || response.data.length == 0) {
        toast.dismiss();
        toast.error("Ocurrió un error al traer la información", estilosAlerta);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al guardar datos",
        estilosAlerta
      );
    }
  }

  const COLUMNAS = [
    {
      Header: "Periodo",
      accessor: (d) => d.anyoInicio + " - " + (d.anyoInicio + 1),
      Filter: ColumnFilter,
    },
    {
      Header: "Días Normales Disp.",
      accessor: (d) =>
        d.tipoPeriodo === "PROPORCIONAL"
          ? d.diasProporcionales + " días"
          : d.diasVacaciones + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Días Progresivos Disp.",
      accessor: (d) => d.feriadoProgresivo + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Total Días",
      accessor: (d) =>
        d.tipoPeriodo === "PROPORCIONAL"
          ? d.diasProporcionales + d.feriadoProgresivo + " días"
          : d.diasVacaciones + d.feriadoProgresivo + " días",
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_VACACIONES_SOLICITADAS = [
    {
      Header: "Días Hábiles Tomados",
      accessor: (d) => d.diasHabilesTotales + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Solicitud",
      accessor: (d) => d.createdAt.slice(0, 10),
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Inicio",
      accessor: "fechaInicio",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Término",
      accessor: "fechaTermino",
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: "estado",
      Filter: ColumnFilter,
    },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
        <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Solicitar Vacaciones</h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {/* {!loadingModal && <ModalSpinner />} */}

              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <h3 className="text-3xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                    Datos del trabajador
                  </h3>
                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-4/12 md:pr-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Trabajador
                      </label>
                      <input
                        disabled
                        {...register("trabajador")}
                        name="nombre"
                        id="nombre"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-2/12 md:px-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        {...register("rut")}
                        name="rut"
                        id="rut"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-2/12 md:pl-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Fecha Ingreso
                      </label>
                      <input
                        disabled
                        {...register("fechaIngreso")}
                        name="fechaIngreso"
                        id="fechaIngreso"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>
                  </div>

                  {periodoVacacionesTrabajador.length  >= 1 ? (
                    <>
                      <h3 className="text-3xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                        Días de vacaciones disponibles por periodo
                      </h3>

                      <div className="w-full md:pr-3 mb-9">
                        <SimpleTable
                          col={COLUMNAS}
                          tableData={periodoVacacionesTrabajador}
                          exportCsvButton={false}
                          hasPagination={false}
                          searchBar={false}
                          showFilters={false}
                          tableHeight="h-56"
                          rowConditional={true}
                          rowConditionalFunc={(row) => row.monto !== 0}
                        />
                      </div>

                      <h3 className="md:ml-[2px] text-3xl font-semibold leading-normal mb-4 dark:text-white text-hercules-light-font-secondary">
                        Agregar Solicitud
                      </h3>

                      <form
                        onSubmit={handleSubmit(solicitarPropuestaVacaciones)}
                      >
                        <div className="h-full md:flex md:ml-[2px]">
                          <div className="w-full md:w-3/12 md:pr-3">
                            <label
                              className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                              htmlFor="grid-last-name"
                            >
                              Tipo periodo
                            </label>
                            <select
                              {...register("tipoPeriodo", {
                                required: {
                                  value: true,
                                  message: "Campo obligatorio",
                                },
                                // onChange: (e) => handleChangePeriodo(e),
                              })}
                              name="tipoPeriodo"
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="" hidden>
                                Seleccionar
                              </option>
                              <option value="1" key="1">
                                Fecha Inicio y Duración
                              </option>
                              <option value="2" key="2">
                                Fecha Inicio y Fecha Termino
                              </option>
                            </select>
                            <span
                              className={`text-red-500 dark:text-red-400 text-xs italic ${
                                !errors.tipoPeriodo && "opacity-0 invisible"
                              }`}
                            >
                              {errors.tipoPeriodo
                                ? errors.tipoPeriodo.message
                                : "hidden"}
                            </span>
                          </div>

                          {watch("tipoPeriodo") === "1" && (
                            <>
                              <div className="w-full md:w-3/12 md:px-3">
                                <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                  Fecha Inicio
                                </label>
                                <input
                                  {...register(
                                    "fechaInicio",
                                    validacionCampoObligatorio
                                  )}
                                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                  type="date"
                                />
                                <span
                                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                                    !errors.fechaInicio && "opacity-0 invisible"
                                  }`}
                                >
                                  {errors.fechaInicio
                                    ? errors.fechaInicio.message
                                    : "hidden"}
                                </span>
                              </div>

                              <div className="div-Inputs-EditarHaber md:w-2/12 md:px-3">
                                <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                  Duración
                                </label>
                                <input
                                  {...register("duracion", {
                                    ...validacionCampoObligatorio,
                                    onChange: (e) => handleChangeDuracion(e),
                                  })}
                                  placeholder="Días"
                                  ref={inputRef}
                                  value={watch("duracion") || ""} 
                                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                  type="text"
                                />
                                <span
                                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                                    !errors.duracion &&
                                    "opacity-0 invisible"
                                  }`}
                                >
                                  {errors.duracion
                                    ? errors.duracion.message
                                    : "hidden"}
                                </span>
                              </div>
                            </>
                          )}


                          {watch("tipoPeriodo") === "2" && (
                            <>
                              <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                                <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                  Fecha Inicio
                                </label>
                                <input
                                  {...register(
                                    "fechaInicio",
                                    validacionCampoObligatorio
                                  )}
                                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                  type="date"
                                />
                                <span
                                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                                    !errors.fechaInicio && "opacity-0 invisible"
                                  }`}
                                >
                                  {errors.fechaInicio
                                    ? errors.fechaInicio.message
                                    : "hidden"}
                                </span>
                              </div>

                              <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                                <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                  Fecha Término
                                </label>
                                <input
                                  min={watchDataFechaInicio}
                                  {...register(
                                    "fechaTermino",
                                    validacionCampoObligatorio
                                  )}
                                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                  type="date"
                                />
                                <span
                                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                                    !errors.fechaTermino &&
                                    "opacity-0 invisible"
                                  }`}
                                >
                                  {errors.fechaTermino
                                    ? errors.fechaTermino.message
                                    : "hidden"}
                                </span>
                              </div>
                            </>
                          )}

                          {watch("tipoPeriodo") !== "" && (
                            <div className="w-full md:pl-3 md:w-3/12 md:mt-6 mt-3">
                              <button
                                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                                type="submit"
                              >
                                Generar
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    <h5 className="text-lg font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                      Este trabajador no tiene dias de vacaciones disponibles
                    </h5>
                  )}

                  {registrosVacaciones.length ? (
                    <>
                      {" "}
                      {registrosVacaciones.map((objeto, index) => (
                        <div className="h-full md:flex mt-9 md:mt-0">
                          <React.Fragment key={`fragment_${index}`}>
                            <div className="div-Inputs-EditarHaber mb-9 md:mb-0 md:w-2/12 md:pr-3">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                Año
                              </label>
                              <input
                                {...register(`anyoPeriodo_${index}`, {
                                  value: `${objeto.anyoPeriodo} - ${
                                    objeto.anyoPeriodo + 1
                                  }`,
                                })}
                                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="text"
                                readOnly
                              />
                            </div>

                            <div className="div-Inputs-EditarHaber mb-9 md:mb-0 md:w-2/12 md:px-3">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                Fecha Inicio
                              </label>
                              <input
                                {...register(`fechaInicio_${index}`, {
                                  value: objeto.fechaInicio,
                                })}
                                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="date"
                              />
                            </div>

                            <div className="div-Inputs-EditarHaber mb-9 md:mb-0 md:w-2/12 md:px-3">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                Fecha Término
                              </label>
                              <input
                                {...register(`fechaTermino_${index}`, {
                                  value: objeto.fechaTermino,
                                })}
                                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="date"
                              />
                            </div>

                            <div className="div-Inputs-EditarHaber mb-9 md:mb-0 md:w-1/12 md:px-3">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                D. habiles
                              </label>
                              <input
                                {...register(`diasHabiles_${index}`, {
                                  value: objeto.diasHabiles,
                                })}
                                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="number"
                              />
                            </div>

                            <div className="div-Inputs-EditarHaber mb-9 md:mb-0 md:w-1/12 md:px-3">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                Feriados
                              </label>
                              <input
                                {...register(`diasFeriados_${index}`, {
                                  value: objeto.diasFeriados,
                                })}
                                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="number"
                              />
                            </div>

                            <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                                Tipo registro
                              </label>
                              <input
                                disabled
                                {...register(`tipoRegistro_${index}`, {
                                  value:
                                    objeto.tipoRegistro ===
                                    "VACACIONES_NORMALES"
                                      ? "Vacaciones Normales"
                                      : objeto.tipoRegistro ===
                                        "VACACIONES_PROGRESIVAS"
                                      ? "Vacaciones Progresivas"
                                      : objeto.tipoRegistro ===
                                          "VACACIONES_PROPORCIONALES" &&
                                        "Vacaciones Proporcionales",
                                })}
                                className="appearance-none block w-full bg-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="text"
                              />
                            </div>
                          </React.Fragment>
                        </div>
                      ))}
                      <button
                        className="mt-6 md:mt-0 mb-6 bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                        type="button"
                        onClick={() => confirmarCambios()}
                      >
                        Guardar
                      </button>
                    </>
                  ) : null}

                  {infoRegistrosVacaciones.length > 0 ? (
                    <div className="w-full md:pr-3 mb-9">
                      <SimpleTable
                        col={COLUMNAS_VACACIONES_SOLICITADAS}
                        tableData={infoRegistrosVacaciones}
                        exportCsvButton={false}
                        hasPagination={false}
                        searchBar={false}
                        showFilters={false}
                        tableHeight="h-56"
                        primaryButton={{
                          type: "ver",
                          onClickFunc: (data) => {
                            setShowModalVerSolicitudVacaciones(true);
                            setVerSolicitudData(data);
                          },
                          active: true,
                        }}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-bluegray-500 dark:border-bluegray-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-sm md:text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>
      {showModalVerSolicitudVacaciones && (
        <ModalVerSolicitudVacaciones
          modalData={verSolicitudData}
          fechaIngresoLegal={modalData.fechaIngresoLegal}
          sucursal={modalData.centroCosto}
          cerrarModal={() => setShowModalVerSolicitudVacaciones(false)}
        />
      )}
    </>
  );
}
