import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentroCostos,
  getCentrosCostosByEmpresaId,
  getSecciones,
} from "../../Redux/Actions";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../common/axiosPrivate";
import NominaBancariaTrabajadores from "../../components/Herramientas/Plantillas/NominaBancariaTabla";
import { ColumnFilter } from "../../components";

export default function PantallasHerramientas() {
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      radio: "",
      tipoInforme: "",
      centroCostoNominaBancaria: "",
    },
  });

  const { activeMenu, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const empresaId = localStorage.getItem("empresaId");
  const periodoId = localStorage.getItem("periodoId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const tokenUser = localStorage.getItem("token-user");
  const token = tokenRut.slice(0, 8);
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const AuthorizationFiniquitos = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
    responseType: "blob",
  };

  useEffect(() => {
    getPeriodosCargados();
    dispatch(getCentroCostos());
    dispatch(getSecciones());
    dispatch(getCentrosCostosByEmpresaId(empresaId));
  }, [dispatch]);

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const [infoTrabajadoresNominaBancaria, setInfoTrabajadoresNominaBancaria] =
    useState([]);
  const listaSeccionArea = useSelector((state) => state.getSecciones);
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);

  async function getPeriodosCargados() {
    const infoMeses = await axiosPrivate(
      `${api_service}/api/v1/periodos/cargados`,
      Authorization
    );
    setInfoPeriodos(infoMeses.data);
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) =>
        UtilsFunctions.integerToMonth(month.mes)
      );
      setMesesData(transformedMonths);
    } else {
      setMesesData([]);
    }
  };

  async function verTrabajadoresNominaBancaria() {
    const passFormValidation = await trigger();
    if (!passFormValidation) {
      return null;
    }
    const data = getValues();
    toast.loading("Cargando trabajadores");
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/liquidaciones/trabajadores/pago/nomina-bancaria/centro-costo/${data.centroCostoNominaBancaria}/periodo/${periodoId}`,
        Authorization
      );
      console.log("response", response);
      if (response.data.length > 0) {
        toast.dismiss();
        toast.success("Trabajadores cargados con éxito");
        setInfoTrabajadoresNominaBancaria(response.data);
      } else {
        toast.dismiss();
        toast.error("Ningún trabajador tiene cuenta bancaria");
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al ver trabajadores"
      );
    }
  }

  async function onSubmit(data) {
    if (data.tipoInforme == "DeclaracionJuradaRentas") {
      generarArchivoPlanoSII(data);
    }
    if (data.tipoInforme == "NominaTrabajadores") {
      exportarNominaTrabajadores(data);
    }
    if (data.tipoInforme == "ArchivoPlanoNominaBancaria") {
      generarArchivoPlanoNominaBancaria(data);
    }
    if (data.tipoInforme == "ArchivoPlanoPagoImposiciones") {
      generarArchivoPlanoPagoImposiciones(data);
    }
    if (data.tipoInforme == "InformeFiniquitos") {
      generarInformeFiniquitos(data);
    }
  }

  async function generarArchivoPlanoSII(data) {
    toast.loading("Generando archivo plano SII", estilosAlerta);

    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/liquidaciones/declaracion-jurada-1887/anio/${data.periodoAnyo}/empresa/${empresaId}/`,
        Authorization
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Archivo Plano SII.csv");
      document.body.appendChild(link);
      link.click();
      toast.dismiss();
      toast.success("Archivo plano SII generado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al descargar archivo plano SII",
        estilosAlerta
      );
    }
  }

  async function exportarNominaTrabajadores(data) {
    toast.loading("Exportando nómina trabajadores", estilosAlerta);
    console.log("data", data);
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/informes/nomina-trabajadores?empresaId=${empresaId}${data.centroCosto !== "0" ? "&centroCostoId=" + data.centroCosto : "" }${data.seccionArea !== "0" ? "&seccionId=" + data.seccionArea : "" }${data.fechaIngresoDesde !== "" ? "&fechaIngresoDesde=" + data.fechaIngresoDesde : "" }${data.fechaIngresoHasta !== "" ? "&fechaIngresoHasta=" + data.fechaIngresoHasta : "" }${data.fechaTerminoDesde !== "" ? "&fechaTerminoDesde=" + data.fechaTerminoDesde : "" }${data.fechaTerminoHasta !== "" ? "&fechaTerminoHasta=" + data.fechaTerminoHasta : "" }`,
        AuthorizationFiniquitos
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Nómina de Trabajadores.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.dismiss();
      toast.success("Nómina trabajadores exportada con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al descargar Nómina trabajadores",
        estilosAlerta
      );
    }
  }

  async function generarArchivoPlanoNominaBancaria(data) {}

  async function generarArchivoPlanoPagoImposiciones(data) {
    toast.loading(
      "Exportando Plantilla para pago de imposiciones",
      estilosAlerta
    );
    console.log("data", data);
    const periodo = await axiosPrivate.get(
      `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
      Authorization
    );
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/trabajadores/plantilla/imposiciones/periodo/${periodo.data.id}/empresa/${empresaId}/centro-costo/${data.centroCostoId}`,
        Authorization
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Plantilla de Pago Imposiciones.txt");
      document.body.appendChild(link);
      link.click();
      toast.dismiss();
      toast.success(
        "Plantilla para pago de imposiciones exportada con éxito",
        estilosAlerta
      );
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al descargar Plantilla para pago de imposiciones",
        estilosAlerta
      );
    }
  }

  async function generarInformeFiniquitos(data) {
    toast.loading("Generando Informe de Finiquitos", estilosAlerta);

    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/informes/finiquitos?empresaId=${empresaId}&centroCostoId=${
          data.centroCostoInformeFiniquitos
        }${data.fechaDesde !== "" ? "&fechaInicio=" + data.fechaDesde : ""}${
          data.fechaTermino !== "" ? "&fechaTermino=" + data.fechaTermino : ""
        }`,
        AuthorizationFiniquitos
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Informe_Finiquitos.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.dismiss();
      toast.success("Informe de Finiquitos generado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al descargar Informe de Finiquitos",
        estilosAlerta
      );
    }
  }

  const Columnas_TrabajadoresNominaBancaria = [
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "Líquido a pagar",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.liquidoAPagar),
      Filter: ColumnFilter,
    },
    {
      Header: "Banco",
      accessor: (d) => d.nombreBanco,
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Plantillas</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full pt-3 flex flex-wrap">
          <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Informe a exportar
            </label>
            <select
              {...register("tipoInforme", {
                onChange: (e) => {
                  console.log("e.target.value", e.target.value);
                  reset({
                    tipoInforme: e.target.value,
                    periodoAnyo: "",
                    radio: "",
                    centroCosto: "-1",
                    seccionArea: "-1",
                    periodoMes: "",
                    periodoAnyo: "",
                  });
                  if (e.target.value == "NominaTrabajadores") {
                    setValue("centroCosto", "0");
                    setValue("seccionArea", "0");
                    console.log("watch", watch());
                  }
                  setInfoTrabajadoresNominaBancaria([]);
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option selected hidden value="">
                Seleccione un informe
              </option>
              <option value="DeclaracionJuradaRentas" key="1">
                Declaración Jurada de Rentas
              </option>
              <option value="NominaTrabajadores" key="2">
                Nómina de Trabajadores
              </option>
              <option value="ArchivoPlanoNominaBancaria" key="3">
                Archivo plano para pago Nómina Bancaria
              </option>
              <option value="ArchivoPlanoPagoImposiciones" key="4">
                Archivo plano para pago Imposiciones
              </option>
              <option value="InformeFiniquitos" key="5">
                Informe Finiquitos
              </option>
            </select>
          </div>

          {/* Caso Declaracion jurada de rentas */}
          {watch("tipoInforme") == "DeclaracionJuradaRentas" && (
            <>
              <div className="w-full md:w-1/12">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Año
                </label>
                <select
                  {...register("periodoAnyo", {
                    required: { value: true, message: "Obligatorio" },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Año
                  </option>
                  {anyosData.map((anyo, i) => (
                    <option value={anyo} key={i}>
                      {anyo}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoAnyo && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                </span>
              </div>
            </>
          )}

          {/* ArchivoPlanoPagoImposiciones */}
          {watch("tipoInforme") == "ArchivoPlanoPagoImposiciones" && (
            <>
              <div className="flex flex-wrap w-full md:w-fit">
                <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[200px] md:pl-3 pr-3 mb-3">
                  <label
                    htmlFor="periodoId"
                    className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                  >
                    Periodo
                  </label>
                  <select
                    {...register("periodoAnyo", {
                      onChange: (e) => {
                        setValue("periodoMes", "");
                        handleYearChange(e.target.value);
                      },
                      required: { value: true, message: "Obligatorio" },
                    })}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option hidden value="">
                      Año
                    </option>
                    {anyosData.map((anyo, i) => (
                      <option value={anyo} key={i}>
                        {anyo}
                      </option>
                    ))}
                  </select>
                  <span
                    className={`dark:text-red-400 text-red-600 text-xs ${
                      !errors.periodoAnyo && "opacity-0 invisible"
                    }`}
                  >
                    {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                  </span>
                </div>

                <div className="w-5/12 md:w-6/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
                  <select
                    {...register("periodoMes", {
                      onChange: (e) => {
                        setValue("numeroMes", e.target.selectedIndex);
                      },
                      required: { value: true, message: "Obligatorio" },
                    })}
                    disabled={watch("periodoAnyo") === "" ? true : false}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option hidden value="">
                      Mes
                    </option>
                    {mesesData.map((mes, i) => (
                      <option value={mes} key={i}>
                        {mes}
                      </option>
                    ))}
                  </select>
                  <span
                    className={`dark:text-red-400 text-red-600 text-xs ${
                      !errors.periodoMes && "opacity-0 invisible"
                    }`}
                  >
                    {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                  </span>
                </div>
              </div>

              <div className="w-6/12 md:w-72 md:pl-3 md:mb-11 mb-9 md:px-3">
                <label class="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Centro de Costo
                </label>
                <select
                  {...register("centroCostoId", {
                    onChange: (e) => {
                      const centroCosto =
                        infoCCosto[e.target.selectedIndex - 1];
                      setValue("centroCosto", centroCosto);
                    },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="0">Todos</option>
                  {infoCCosto.map((cCosto, i) => (
                    <option value={cCosto.id} key={i}>
                      {cCosto.nombre}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          {/* Archivo Plano para pago nomina bancaria */}
          {watch("tipoInforme") == "ArchivoPlanoNominaBancaria" && (
            <>
              <div className="w-full md:mx-4 md:w-2/12 md:mb-0 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Centro de Costo
                </label>
                <select
                  {...register("centroCostoNominaBancaria", {
                    required: { value: true, message: "Obligatorio" },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="" hidden>
                    Seleccionar
                  </option>
                  {infoCCosto.map((cCosto, i) => (
                    <option value={cCosto.id} key={i}>
                      {cCosto.nombre}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.centroCostoNominaBancaria && "opacity-0 invisible"
                  }`}
                >
                  {errors.centroCostoNominaBancaria
                    ? errors.centroCostoNominaBancaria.message
                    : "hidden"}
                </span>
              </div>

              <div className="w-full md:px-3 md:mt-6 md:mb-0 md:w-3/12">
                <button
                  type="button"
                  onClick={() => verTrabajadoresNominaBancaria()}
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                >
                  Ver Trabajadores
                </button>
              </div>
            </>
          )}

          {watch("tipoInforme") == "InformeFiniquitos" && (
            <>
              <div className="w-full md:mx-4 md:w-2/12 md:mb-0 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Centro de Costo
                </label>
                <select
                  {...register("centroCostoInformeFiniquitos", {
                    required: { value: true, message: "Obligatorio" },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="0" hidden>
                    Todos
                  </option>
                  {infoCCosto.map((cCosto, i) => (
                    <option value={cCosto.id} key={i}>
                      {cCosto.nombre}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.centroCostoInformeFiniquitos &&
                    "opacity-0 invisible"
                  }`}
                >
                  {errors.centroCostoInformeFiniquitos
                    ? errors.centroCostoInformeFiniquitos.message
                    : "hidden"}
                </span>
              </div>

              <div className="w-full md:w-2/12 px-3 mb-6">
                <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                  Desde
                </label>
                <input
                  {...register("fechaDesde")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>

              <div className="w-full md:w-2/12 px-3 mb-6">
                <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                  Hasta
                </label>
                <input
                  {...register("fechaTermino")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>
            </>
          )}

          {watch("tipoInforme") !== "" &&
            watch("tipoInforme") !== "ArchivoPlanoNominaBancaria" &&
            watch("tipoInforme") !== "NominaTrabajadores" && (
              <div
                className={`w-full md:px-3 md:mt-6 md:mb-0 ${
                  watch("tipoInforme") == "DeclaracionJuradaRentas"
                    ? "md:w-3/12"
                    : "md:w-2/12"
                }`}
              >
                <button
                  type="submit"
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                >
                  {watch("tipoInforme") === "DeclaracionJuradaRentas"
                    ? "Generar archivo plano SII"
                    : "Exportar"}
                </button>
              </div>
            )}
        </div>

        {/* Nomina bancaria */}
        {watch("tipoInforme") == "NominaTrabajadores" && (
          <>
            <div className="w-full flex flex-wrap">
              <div className="w-full md:mr-4 md:w-2/12 md:mb-11 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Centro de Costo
                </label>
                <select
                  {...register("centroCosto")}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="0">Todos</option>
                  {infoCCosto.map((cCosto, i) => (
                    <option value={cCosto.id} key={i}>
                      {cCosto.nombre}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full md:mx-4 md:w-2/12 md:mb-11 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Sección/Área
                </label>
                <select
                  {...register("seccionArea")}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="0">Todas</option>
                  {listaSeccionArea.map((seccionArea, i) => (
                    <option value={seccionArea.id} key={i}>
                      {seccionArea.nombre}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Fecha Ingreso Legal (opcional)
            </label>
            <div className="w-full mt-2 flex flex-wrap">
              <div className="w-full md:mr-4 md:w-2/12 md:mb-11 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Desde
                </label>
                <input
                  {...register("fechaIngresoDesde")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>

              <div className="w-full md:mx-4 md:w-2/12 md:mb-11 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Hasta
                </label>
                <input
                  {...register("fechaIngresoHasta")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>
            </div>

            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Fecha Termino Contrato (opcional)
            </label>
            <div className="w-full mt-2 flex flex-wrap">
              <div className="w-full md:mr-4 md:w-2/12 md:mb-11 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Desde
                </label>
                <input
                  {...register("fechaTerminoDesde")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>

              <div className="w-full md:mx-4 md:w-2/12 md:mb-11 mb-9">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Hasta
                </label>
                <input
                  {...register("fechaTerminoHasta")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>
            </div>

            <button
              type="submit"
              className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Exportar
            </button>
          </>
        )}
      </form>
      {infoTrabajadoresNominaBancaria.length > 0 && (
        <>
          <div className="h-full md:mr-12 rounded-lg">
            <NominaBancariaTrabajadores
              col={Columnas_TrabajadoresNominaBancaria}
              tableData={infoTrabajadoresNominaBancaria}
            />
          </div>
        </>
      )}
    </div>
  );
}
