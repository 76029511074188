import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components";
import {
  MantenedorDescuentos,
  MantenedorJornadas,
  MantenedorHorasExtra,
  RepLegales,
  Login,
  LandingPage,
  Cargos,
  CausalesTerminoContrato,
  IndicadoresPrevisionales,
  Ips,
  MovimientosPersonal,
  PrevisionSalud,
  SeccionArea,
  Trabajadores,
  PerfilHolding,
  Roles,
  Accesos,
} from "./pages";
import Usuarios2 from "./pages/Parametros/Usuarios";
import GruposSindicatos from "./pages/Edicion/GruposSindicatos";
import FactoresActualizacion from "./pages/Parametros/FactoresActualizacion";
import CalculoLiquidacion from "./pages/Movimientos/CalculoLiquidacion";
import DescuentoEnCuotas from "./pages/Movimientos/DescuentoCuotas";
import ImpresionLiquidaciones from "./pages/Movimientos/ImpresionLiquidaciones";
import { Toaster } from "react-hot-toast";
import SeleccionarEmpresayPeriodo from "./pages/SelectEmpresaYPeriodo";
import MantenedorContratosAnexos from "./pages/Edicion/MantenedorContratosAnexos";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Auth from "./pages/Auth";
import ImpresionLiquidacionesIndividuales from "./pages/Movimientos/ImpresionLiquidacionesIndividual";
import MovimientosHaberesDescuentos from "./pages/Movimientos/MovimientosHaberesDescuentos"
import IngresoCargasFamiliares from "./pages/Movimientos/IngresoCargasFamiliares";
import MantenedorEmpresas from "./pages/Edicion/MantenedorEmpresa";
import MantenedorCentrosDeCosto from "./pages/Edicion/MantenedorCentrosDeCosto";
import MantenedorHaberes from "./pages/Edicion/MantenedorHaberes";
import CertificadoSueldo from "./pages/Herramientas/CertificadoSueldo";
import FiniquitosEmpresa from "./pages/Herramientas/FiniquitosEmpresa";
import PantallasHerramientas from "./pages/Herramientas/PlantillasHerramientas";
import { DarkModeProvider } from "./contexts/DarkModeProvider";
import EliminarLiquidacionCalculada from "./pages/Herramientas/EliminarLiquidacionCalculada";
import CerrarAbrirMes from "./pages/Herramientas/CerrarAbrirMes";
import ActualizarHaberesDescuentos from "./pages/Herramientas/ActualizarHaberesDescuentos";
import InformesHaberesDescuentos from "./pages/Informes/InformesHaberesDescuentos";
import ResumenPagoImposiciones from "./pages/Informes/ResumenPagoImposiciones";
import DeclaracionJuradaIngresoCargaFamiliar from "./pages/Informes/DeclaracionJuradaCargaFamiliar";
import InformeCentroCostos from "./pages/Informes/InformeCentroCostos";
import Inicio from "./pages/Inicio";
import NuevoInformeDescuentoCuotas from "./pages/Movimientos/NuevoInformeDescuentoCuotas";
import NuevoLibroRemuneraciones from "./pages/Herramientas/NuevoLibroRemuneraciones";
import NuevoLibroRemuneracionesMensual from "./pages/Herramientas/NuevoLibroRemuneracionesMensual";
import SeleccionarEmpresayCCosto from "./modulos/vacaciones/pages/SelectEmpresaYCCosto";
import SolicitudVacaciones from "./modulos/vacaciones/pages/SolicitudVacaciones";
import LayoutVacaciones from "./modulos/vacaciones/components/LayoutVacaciones";
import SeleccionarModulo from "./pages/SeleccionarModulo";
import GestionarVacaciones from "./modulos/vacaciones/pages/GestionarVacaciones";
import AgregarPeriodo from "./modulos/vacaciones/pages/AgregarPeriodo";
import AdministrarFeriados from "./modulos/vacaciones/pages/Feriados";
import InicioVacaciones from "./modulos/vacaciones/pages/InicioVacaciones";
import ActualizarJornadaContratos from "./pages/Herramientas/ActualizarJornadaContratos";
import NuevoMovimientosTrabajador from "./pages/Movimientos/NuevoMovimientosTrabajador";
import AnularVacaciones from "./modulos/vacaciones/pages/AnularVacaciones";
import Eventos from "./pages/Edicion/Eventos";
import NuevoIngresoCreditosCCAF from "./pages/Movimientos/NuevoIngresoCreditosCCAF";
import DescuentosFijos from "./pages/Edicion/DescuentosFijos";
import CanjearHorasExtra from "./pages/Movimientos/CanjearHorasExtra";
import GenerarComprobanteVacaciones from "./pages/Movimientos/GenerarComprobanteVacaciones";
// import InformeLiquidacionesDepositadas from "./pages/Movimientos/InformeLiquidacionesDepositadas";
import RegistroFamiliar from "./pages/Movimientos/RegistroFamiliar";
import AdministrarPlantillas from "./pages/Herramientas/AdministrarPlantillas";
import ImprimirPlantillas from "./pages/Herramientas/ImprimirPlantillas";
import Page404 from "./pages/page404";


function App() {
  return (
    <>
    <DarkModeProvider>
      <div  className="App">
        <Toaster />
        <BrowserRouter>
          <div className="flex relative overflow-clip dark:bg-main-dark-bg">
            <Routes>
              {/* LandingPage y Login */}
              <Route path="/" element={<LandingPage />} />
              <Route path="login" element={<Login />} />
              <Route path="recuperar-contraseña" element={<ForgotPassword />} />
              <Route path="restablecer-contraseña" element={<ResetPassword />}/>
              {/* Seleccionar módulo */}
              <Route
                path="seleccionar-modulo"
                element={<SeleccionarModulo />}
              />

              {/* Seleccionar empresa y periodo */}
              <Route
                path="seleccionar-empresa-periodo"
                element={<SeleccionarEmpresayPeriodo />}
              />
              {/* Seleccionar empresa y centro costo */}
              <Route
                path="seleccionar-empresa-centro-costo"
                element={<SeleccionarEmpresayCCosto />}
              />


              <Route element={<LayoutVacaciones />}>

              <Route path="vacaciones">

              <Route element={<Auth menuPath={["Inicio Vacaciones"]} />} >
                  <Route path="inicio" element={<InicioVacaciones />} />
                </Route>
                
                <Route element={<Auth menuPath={["Solicitar Vacaciones"]} />}>
                  <Route path="solicitud-vacaciones" element={<SolicitudVacaciones />} />
                </Route>

                <Route element={<Auth menuPath={["Gestionar Vacaciones"]} />}>
                <Route path="gestionar-vacaciones" element={<GestionarVacaciones />} />
                </Route>

                <Route >
                <Route path="anular-vacaciones" element={<AnularVacaciones />} />
                </Route>

                <Route element={<Auth menuPath={["Agregar Periodo"]} />}>
                  <Route path="agregar-periodo" element={<AgregarPeriodo/>} />
                </Route>

                <Route element={<Auth menuPath={["Administrar Feriados"]} />}>
                  <Route path="feriados" element={<AdministrarFeriados />} />
                </Route>

                </Route>
                </Route>

              <Route element={<Layout />}>

                <Route element={<Auth menuPath={["Inicio"]} />} >
                  <Route path="inicio" element={<Inicio />} />
                </Route>

                {/* Edicion */}
                <Route path="edicion">

                <Route element={<Auth menuPath={["Edición", "Representantes legales"]} />} >
                    <Route path="representantes-legales" element={<RepLegales />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Empresas"]} />} >
                    <Route path="empresas" element={<MantenedorEmpresas />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Indicadores previsionales"]} />} >
                    <Route path="indicadores-previsionales" element={<IndicadoresPrevisionales />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Previsión de salud"]} />} >
                    <Route path="prevision-salud" element={<PrevisionSalud />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Haberes"]} />} >
                    <Route path="haberes" element={<MantenedorHaberes />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Descuentos"]} />} >
                    <Route path="descuentos" element={<MantenedorDescuentos />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Centros de costo"]} />} >
                    <Route path="centros-costo" element={<MantenedorCentrosDeCosto />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Sección/Área"]} />} >
                    <Route path="seccion-area" element={<SeccionArea />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Cargos"]} />} >
                    <Route path="cargos" element={<Cargos />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Jornadas laborales"]} />} >
                    <Route path="jornadas-laborales" element={<MantenedorJornadas />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Horas extra"]} />} >
                    <Route path="horas-extra" element={<MantenedorHorasExtra />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Trabajadores"]} />} >
                    <Route path="trabajadores" element={<Trabajadores />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Contratos"]} />} >
                    <Route path="contratos-anexos" element={<MantenedorContratosAnexos />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Descuentos Fijos"]} />}>
                    <Route path="descuentos-fijos" element={<DescuentosFijos />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Causales de término de contrato"]} />} >
                    <Route path="causales-termino-contrato" element={<CausalesTerminoContrato />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Grupos de sindicatos"]} />} >
                    <Route path="sindicatos" element={<GruposSindicatos />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Movimientos de personal"]} />} >
                    <Route path="movimientos-personal" element={<MovimientosPersonal />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "IPS (Ex INP)"]} />} >
                    <Route path="ips" element={<Ips />} />
                  </Route>

                  <Route element={<Auth menuPath={["Edición", "Eventos"]} />} >
                    <Route path="eventos" element={<Eventos />} />
                  </Route>

                </Route>
                {/* Parametros */}
                <Route path="parametros">
                  <Route path="configuracion">

                    <Route element={<Auth menuPath={["Parámetros","Configuración", "Perfil Holding"]} />} >
                      <Route path="perfil-holding" element={<PerfilHolding />} />
                    </Route>

                    <Route element={<Auth menuPath={["Parámetros","Configuración", "Roles"]} />} >
                      <Route path="roles" element={<Roles />} />
                    </Route>

                    <Route element={<Auth menuPath={["Parámetros","Configuración", "Usuarios"]} />} >
                      <Route path="usuarios" element={<Usuarios2 />} />
                    </Route>

                    <Route element={<Auth menuPath={["Parámetros","Configuración", "Accesos"]} />} >
                      <Route path="accesos" element={<Accesos />} />
                    </Route>

                  </Route>

                  <Route element={<Auth menuPath={["Parámetros", "Factores de actualización"]} />} >
                    <Route path="factores-actualizacion" element={<FactoresActualizacion />} />
                  </Route>

                </Route>
                {/* Movimientos */}
                <Route path="movimientos">

                <Route element={<Auth menuPath={["Movimientos", "Movimientos de haberes/descuentos"]} />} >
                    <Route path="haberes-descuentos" element={<MovimientosHaberesDescuentos />} />
                  </Route>

                  <Route element={<Auth menuPath={["Movimientos", "Movimientos de Trabajadores"]} />} >
                    <Route path="movimientos-trabajadores" element={<NuevoMovimientosTrabajador />} />
                  </Route>
                  
                  <Route element={<Auth menuPath={["Movimientos", "Descuento en cuotas"]} />} >
                    <Route path="descuento-cuotas" element={<DescuentoEnCuotas />} />
                    <Route path="informe-descuento-cuotas" element={<NuevoInformeDescuentoCuotas />} />
                  </Route>

                  <Route element={<Auth menuPath={["Movimientos", "Ingreso créditos CCAF"]} />} >
                    <Route path="ingreso-creditos-ccaf" element={<NuevoIngresoCreditosCCAF />} />
                  </Route>

                  <Route element={<Auth menuPath={["Movimientos", "Ingreso cargas familiares"]} />} >
                    <Route path="ingreso-cargas-familiares" element={<IngresoCargasFamiliares />} />
                  </Route>

                  <Route element={<Auth menuPath={["Movimientos", "Cálculo de liquidación"]} />} >
                    <Route path="calculo-liquidaciones" element={<CalculoLiquidacion />} />
                  </Route>

                  <Route element={<Auth menuPath={["Movimientos", "Impresión de liquidaciones"]} />} >
                    <Route path="impresion-liquidaciones" element={<ImpresionLiquidaciones />} />
                    <Route path="impresion-liquidaciones-individuales" element={<ImpresionLiquidacionesIndividuales />} />
                  </Route>

                  <Route>
                    <Route path="canjear-horas-extra" element={<CanjearHorasExtra />} />
                  </Route>

                  <Route>
                    <Route path="generar-comprobante-vacaciones" element={<GenerarComprobanteVacaciones />} />
                  </Route>

                  {/* <Route >
                    <Route path="informe-liquidaciones-depositadas" element={<InformeLiquidacionesDepositadas />} />
                  </Route> */}

                  <Route >
                    <Route path="registro-familiar" element={<RegistroFamiliar />} />
                  </Route>

                </Route>
                {/* Herramientas */}
                <Route path="herramientas">

                <Route element={<Auth menuPath={["Herramientas", "Libro de remuneraciones"]} />} >
                    <Route path="libro-remuneraciones" element={<NuevoLibroRemuneraciones />} />
                    <Route path="libro-remuneraciones/mensual" element={<NuevoLibroRemuneracionesMensual />} />
                  </Route>

                  <Route element={<Auth menuPath={["Herramientas", "Certificados de Sueldo"]} />} >
                    <Route path="certificados-sueldo" element={<CertificadoSueldo />} />
                  </Route>

                  <Route element={<Auth menuPath={["Herramientas", "Plantillas"]} />} >
                    <Route path="plantillas" element={<PantallasHerramientas />} />
                  </Route>

                  <Route element={<Auth menuPath={["Herramientas", "Finiquitos empresa"]} />} >
                    <Route path="finiquitos-empresa" element={<FiniquitosEmpresa />} />
                  </Route>

                  <Route element={<Auth menuPath={["Herramientas", "Eliminar liquidación calculada"]} />} >
                    <Route path="eliminar-liquidacion-calculada" element={<EliminarLiquidacionCalculada />} />
                  </Route>

                  <Route element={<Auth menuPath={["Herramientas", "Cerrar o abrir mes en proceso"]} />} >
                    <Route path="cerrar-abrir-mes" element={<CerrarAbrirMes/>} />
                  </Route>

                  <Route element={<Auth menuPath={["Herramientas", "Actualizar haberes/descuentos"]} />} >
                    <Route path="actualizar-haberes-descuentos" element={<ActualizarHaberesDescuentos/>} />
                  </Route>

                  <Route>
                    <Route path="actualizar-jornadas-contratos" element={<ActualizarJornadaContratos/>} />
                  </Route>
                  
                  <Route>
                    <Route path="administrar-plantillas" element={<AdministrarPlantillas/>} />
                  </Route>

                  <Route>
                    <Route path="imprimir-plantillas" element={<ImprimirPlantillas/>} />
                  </Route>

                </Route>

                <Route path="informes">

                <Route element={<Auth menuPath={["Informes", "Informes de haberes y descuentos"]} />} >
                    <Route path="haberes-descuentos" element={<InformesHaberesDescuentos/>} />
                  </Route>

                  <Route element={<Auth menuPath={["Informes", "Resumen pago imposiciones"]} />} >
                    <Route path="resumen-pago-imposiciones" element={<ResumenPagoImposiciones/>} />
                  </Route>

                  <Route element={<Auth menuPath={["Informes", "Declaración jurada ingreso carga familiar"]} />} >
                    <Route path="declaracion-jurada-ingreso-carga-familiar" element={<DeclaracionJuradaIngresoCargaFamiliar/>} />
                  </Route>

                  <Route element={<Auth menuPath={["Informes", "Informe centro costos"]} />} >
                    <Route path="centro-costos" element={<InformeCentroCostos/>} />
                  </Route>

                </Route>

                <Route path="*" element={<Page404 />} />

              </Route>

            </Routes>
          </div>
        </BrowserRouter>
      </div>
      </DarkModeProvider>
    </>
  );
}

export default App;
