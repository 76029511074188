import { useLocation, Navigate, Outlet } from "react-router-dom";

function checkIfUserCanAccessMenu(menuPath, userMenu) {
    let currentMenu = userMenu;

    for (const title of menuPath) {
        const menu = currentMenu.find((menu) => menu.title === title);

        if (!menu) {
            localStorage.clear();
            return false;
        }

        currentMenu = menu.childrens;
    }

    return true;
}

const Auth = ({ menuPath }) => {
    const userAuthString = localStorage.getItem("userAuth");
    const userMenuString = localStorage.getItem("userMenu");

    const userAuth = JSON.parse(userAuthString);
    const userMenu = JSON.parse(userMenuString);

    if (!userAuth || !userMenu) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    if (!userAuth.token || !userAuth.refreshToken) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    return (
        checkIfUserCanAccessMenu(menuPath, userMenu) ?
            <Outlet /> :
            <Navigate to="/login" />
    );
    
};

export default Auth;