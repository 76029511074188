import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCiudades,
  getComunas,
  getRegiones,
  getSindicatosActivos,
  limpiarInfoMenuEdicion,
  obtenerTrabajador,
} from "../../../../Redux/Actions";
import SeguroCesantiaPopUp from "../SeguroCesantiaPopUp";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { wait } from "@testing-library/user-event/dist/utils";
import ModalSpinner from "../../../ModalSpinner";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function PaginaUnoEditarTrabajador({
  onClose,
  changePage,
  datosTrabajadorEditar,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paisId: "",
      regionId: "",
      comunaId: "",
      ciudadId: "",
    },
  });

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const [seguroCesantiaHabilitado, setSeguroCesantiaHabilitado] =
    useState(true);
  const [showSeguroCesantiaAlert, setShowSeguroCesantiaAlert] = useState(false);
  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validateRutTrabajador = (rut) => {
    if (UtilsFunctions.validateRut(rut)) {
      return true;
    }
    return false || "El RUT ingresado no es válido";
  };

  const dispatch = useDispatch();

  const getPaisId = watch("paisId");
  const getRegionId = watch("regionId");
  const getComunaId = watch("comunaId");
  const getCiudadId = watch("ciudadId");

  const infoTrabajador = useSelector((state) => state.trabajador);

  useEffect(() => {
    dispatch(getSindicatosActivos());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajador"));
      dispatch(limpiarInfoMenuEdicion("ciudades"));
      dispatch(limpiarInfoMenuEdicion("comunas"));
      reset({});
    };
  }, []);

  useEffect(() => {
    if (datosTrabajadorEditar?.id) {
      dispatch(obtenerTrabajador(datosTrabajadorEditar.id));
    }
  }, [datosTrabajadorEditar?.id]);

  useEffect(() => {
    if (infoTrabajador && infoTrabajador.id) {
      reset({
        rutTrabajador: infoTrabajador.rut,
        nombres: infoTrabajador.nombres,
        apellidoPaterno: infoTrabajador.apellidoPaterno,
        apellidoMaterno: infoTrabajador.apellidoMaterno,
        calle: infoTrabajador.calle,
        numero: infoTrabajador.numero,
        depto: infoTrabajador.departamento,
        villa: infoTrabajador.villa,
        paisId: infoTrabajador.ciudad?.comuna?.region?.pais?.id,
        regionId: infoTrabajador.ciudad?.comuna?.region?.id,
        comunaId: infoTrabajador.ciudad?.comuna?.id,
        ciudadId: infoTrabajador.ciudad?.id,
        fechaNacimiento: infoTrabajador.fechaNacimiento,
        nacionalidadId: infoTrabajador.nacionalidadId,
        estadoCivilId: infoTrabajador.estadoCivilId,
        sexoId: infoTrabajador.sexoId,
        telefono: infoTrabajador.telefono,
        email: infoTrabajador.email,
        tipoTrabajadorId: infoTrabajador.tipoTrabajadorId,
        subsidioTrabajadorJoven:
          infoTrabajador.subsidioTrabajadorJovenId == 1
            ? true
            : infoTrabajador.subsidioTrabajadorJovenId == 2 && false,
        tipoCotizanteId: infoTrabajador.tipoCotizanteId,
        regimenPrevisionalId: infoTrabajador.regimenPrevisionalId,
        cajaExRegimenId: infoTrabajador.cajaExRegimenId,
        cajaExRegimenDesahucioId: infoTrabajador.cajaExRegimenDesahucioId,
        grupoSindicatoId: infoTrabajador.sindicatoId,
        seguroCesantia: infoTrabajador.seguroCesantia,
        formaDePago: infoTrabajador.formaPago,
        bancoDestinoId: infoTrabajador.trabajadorNominaElectronica[0]?.bancoId,
        tipoCuentaId:
          infoTrabajador.trabajadorNominaElectronica[0]?.tipoCuentaId,
        numeroCuenta:
          infoTrabajador.trabajadorNominaElectronica[0]?.numeroCuenta,
        tipoImpuestoRentaId: infoTrabajador.tipoImpuestoId,
        personaConDiscapacidadId: infoTrabajador.personaConDiscapacidadId,
        tecnicoExtranjero: infoTrabajador.tecnicoExtranjero,
        feriadoProgresivo: datosTrabajadorEditar.feriadoProgresivo,
        fechaFeriadoProgresivo: datosTrabajadorEditar.fechaFeriadoProgresivo,
      });
    }
  }, [infoTrabajador]);

  useEffect(() => {
    // dispatch(getPaises());
    if (getPaisId !== "") {
      dispatch(getRegiones(getPaisId));
    }
  }, [getPaisId]);

  useEffect(() => {
    if (getRegionId !== "") {
      dispatch(getComunas(getRegionId));
    }
  }, [getRegionId]);

  useEffect(() => {
    if (getComunaId !== "") {
      dispatch(getCiudades(getComunaId));
    }
  }, [getComunaId]);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false);
    toast.loading("Editando Trabajador", estilosAlerta);
    const payload = {
      trabajadorId: datosTrabajadorEditar.id,
      rutTrabajador: data.rutTrabajador,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      nombres: data.nombres,
      calle: data.calle,
      numero: data.numero,
      depto: data.depto,
      villa: data.villa,
      ciudadId: data.ciudadId,
      telefono: data.telefono,
      email: data.email,
      nacionalidadId: data.nacionalidadId,
      fechaNacimiento: data.fechaNacimiento,
      estadoCivilId: data.estadoCivilId,
      sexoId: data.sexoId,
      tipoCotizanteId: data.tipoCotizanteId,
      tipoTrabajadorId: data.tipoTrabajadorId,
      subsidioTrabajoJoven: data.subsidioTrabajadorJoven,
      regimenPrevisionalId: data.regimenPrevisionalId,
      cajaExRegimenId: data.cajaExRegimenId,
      cajaExRegimenDesahucioId: data.cajaExRegimenDesahucioId,
      seguroCesantia: data.seguroCesantia,
      tipoImpuestoRentaId: data.tipoImpuestoRentaId,
      tipoDiscapacidadId: data.personaConDiscapacidadId,
      pensionadoVejez: data.pensionadoVejez,
      tecnicoExtranjero: data.tecnicoExtranjero,
      formaDePago: data.formaDePago,
      tipoCuentaId: data.tipoCuentaId,
      bancoDestinoId: data.bancoDestinoId,
      numeroCuenta: data.numeroCuenta,
      sindicatoId: data.grupoSindicatoId,
      fechaAfiliacionAfp: data.fechaAfiliacionAfp,
      feriadoProgresivo: data.feriadoProgresivo,
      fechaFeriadoProgresivo:
        data.feriadoProgresivo == true ? data.fechaFeriadoProgresivo : null,
      periodoId: periodoId,
    };
    await axiosPrivate
      .put(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/editartrabajador`,
        payload,
        Authorization
      )
      .then((res) => {
        toast.dismiss();
        toast.success("Trabajador editado con éxito", estilosAlerta);
        toast.loading(
          "Redireccionando a ficha de leyes sociales",
          estilosAlerta
        );
        wait(2000);
        toast.dismiss();
        changePage(datosTrabajadorEditar, "2", datosFichaTrabajador?.apv);
        setBotonGuardarHabilitado(true);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(
          err.response.data.message
            ? err.response.data.message
            : "Error editar Trabajador",
          estilosAlerta
        );
        console.log(err);
        setBotonGuardarHabilitado(true);
      });
    return null;
  };

  const paises = useSelector((state) => state.paises);
  const regiones = useSelector((state) => state.regiones);
  const comunas = useSelector((state) => state.comunas);
  const ciudades = useSelector((state) => state.ciudades);

  const datosFichaTrabajador = useSelector((state) => state.datosTrabajador);

  const nacionalidades = datosFichaTrabajador?.nacionalidad;
  const bancos = datosFichaTrabajador?.bancoDestino;
  const cajasExRegimen = datosFichaTrabajador?.cajaExRegimen;
  const cajasExRegimenDesahucio = datosFichaTrabajador?.cajaExRegimenDesahucio;
  const estadosCiviles = datosFichaTrabajador?.estadoCivil;
  const formasDePago = datosFichaTrabajador?.formasDePago;
  const personasConDiscapacidad = datosFichaTrabajador?.personaConDiscapacidad;
  const regimenPrevisionales = datosFichaTrabajador?.regimenPrevisional;
  const sexos = datosFichaTrabajador?.sexo;
  const sindicatos = useSelector((state) => state.getSindicatosActivos);
  const tipoCotizantes = datosFichaTrabajador?.tipoCotizante;
  const tipoTrabajadores = datosFichaTrabajador?.tipoTrabajador;
  const tiposCuenta = datosFichaTrabajador?.tipoCuenta;
  const tiposImpuestoRenta = datosFichaTrabajador?.tipoImpuestoRenta;

  const loadingModal =
    paises.length > 0 &&
    regiones.length > 0 &&
    comunas.length > 0 &&
    ciudades.length > 0 &&
    nacionalidades.length > 0 &&
    bancos.length > 0 &&
    cajasExRegimen.length > 0 &&
    cajasExRegimenDesahucio.length > 0 &&
    estadosCiviles.length > 0 &&
    formasDePago.length > 0 &&
    personasConDiscapacidad.length > 0 &&
    regimenPrevisionales.length > 0 &&
    sexos.length > 0 &&
    // sindicatos.length > 0 &&
    tipoCotizantes.length > 0 &&
    tipoTrabajadores.length > 0 &&
    tiposCuenta.length > 0 &&
    tiposImpuestoRenta.length > 0 &&
    watch("regionId") !== "";

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh]">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Editar Trabajador</h3>
            </div>
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <h3 className="text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                    Identificación del Trabajador
                  </h3>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-nombre-fantasia"
                      >
                        RUT Trabajador
                      </label>
                      <input
                        {...register("rutTrabajador", {
                          required: { value: true, message: "Obligatorio" },
                          validate: (value) => validateRutTrabajador(value),
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-rut"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.rutTrabajador && "opacity-0 invisible"
                        }`}
                      >
                        {errors.rutTrabajador
                          ? errors.rutTrabajador.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Apellido Paterno
                      </label>
                      <input
                        {...register("apellidoPaterno", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.apellidoPaterno && "opacity-0 invisible"
                        }`}
                      >
                        {errors.apellidoPaterno
                          ? errors.apellidoPaterno.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Apellido Materno
                      </label>
                      <input
                        {...register("apellidoMaterno", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.apellidoMaterno && "opacity-0 invisible"
                        }`}
                      >
                        {errors.apellidoMaterno
                          ? errors.apellidoMaterno.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Nombres
                      </label>
                      <input
                        {...register("nombres", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.nombres && "opacity-0 invisible"
                        }`}
                      >
                        {errors.nombres ? errors.nombres.message : "hidden"}
                      </span>
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-nombre-fantasia"
                      >
                        Calle
                      </label>
                      <input
                        {...register("calle", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-rut"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.calle && "opacity-0 invisible"
                        }`}
                      >
                        {errors.calle ? errors.calle.message : "hidden"}
                      </span>
                    </div>
                    <div className="div-Inputs-EditarHaber md:w-2/12 md:px-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Número
                      </label>
                      <input
                        {...register("numero", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.numero && "opacity-0 invisible"
                        }`}
                      >
                        {errors.numero ? errors.numero.message : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-2/12 md:px-3 mb-9">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Depto/of. (opcional)
                      </label>
                      <input
                        {...register("depto")}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Villa/Población/Condominio
                      </label>
                      <input
                        {...register("villa")}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.villa && "opacity-0 invisible"
                        }`}
                      >
                        {errors.villa ? errors.villa.message : "hidden"}
                      </span>
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-2/12 md:pr-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        País
                      </label>
                      <select
                        {...register("paisId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        value={getPaisId}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        {paises.map((pais) => (
                          <option value={pais.id} key={pais.id}>
                            {pais.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.paisId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.paisId ? errors.paisId.message : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Región
                      </label>
                      <select
                        {...register("regionId", {
                          required: { value: true, message: "Obligatorio" },
                          onChange: (e) => {
                            setValue("comunaId", "");
                            setValue("ciudadId", "");
                          },
                        })}
                        value={getRegionId}
                        id="regionId"
                        className=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        {regiones.map((region) => (
                          <option value={region.id} key={region.id}>
                            {region.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.regionId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.regionId ? errors.regionId.message : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Comuna
                      </label>
                      <select
                        disabled={getRegionId === "" ? true : false}
                        {...register("comunaId", {
                          required: { value: true, message: "Obligatorio" },
                          onChange: (e) => {
                            setValue("ciudadId", "");
                          },
                        })}
                        value={getComunaId}
                        id="comunaId"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="" hidden>
                          {getRegionId === ""
                            ? "Seleccione una región"
                            : "Seleccione una comuna"}
                        </option>
                        {comunas.map((comuna) => (
                          <option value={comuna.id} key={comuna.id}>
                            {comuna.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.comunaId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.comunaId ? errors.comunaId.message : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Ciudad
                      </label>
                      <select
                        disabled={getComunaId === "" ? true : false}
                        {...register("ciudadId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        value={getCiudadId}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          {getRegionId === ""
                            ? "Seleccione una región"
                            : getComunaId === ""
                            ? "Seleccione una comuna"
                            : "Seleccione una ciudad"}
                        </option>
                        {ciudades.map((ciudad) => (
                          <option value={ciudad.id} key={ciudad.id}>
                            {ciudad.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.ciudadId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.ciudadId ? errors.ciudadId.message : "hidden"}
                      </span>
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Fecha de Nacimiento
                      </label>
                      <input
                        {...register("fechaNacimiento", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="date"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.fechaNacimiento && "opacity-0 invisible"
                        }`}
                      >
                        {errors.fechaNacimiento
                          ? errors.fechaNacimiento.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Nacionalidad
                      </label>
                      <select
                        {...register("nacionalidadId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        {nacionalidades.map((nacionalidad) => (
                          <option value={nacionalidad.id} key={nacionalidad.id}>
                            {nacionalidad.glosa}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.nacionalidadId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.nacionalidadId
                          ? errors.nacionalidadId.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Estado Civil
                      </label>
                      <select
                        {...register("estadoCivilId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        {estadosCiviles.map((estadoCivil) => (
                          <option value={estadoCivil.id} key={estadoCivil.id}>
                            {estadoCivil.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.estadoCivilId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.estadoCivilId
                          ? errors.estadoCivilId.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Sexo
                      </label>
                      <select
                        {...register("sexoId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        {sexos.map((sexo) => (
                          <option value={sexo.id} key={sexo.id}>
                            {sexo.glosa}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.sexoId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.sexoId ? errors.sexoId.message : "hidden"}
                      </span>
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Teléfono
                      </label>
                      <input
                        {...register("telefono", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.telefono && "opacity-0 invisible"
                        }`}
                      >
                        {errors.telefono ? errors.telefono.message : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Email
                      </label>
                      <input
                        {...register("email", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.email && "opacity-0 invisible"
                        }`}
                      >
                        {errors.email ? errors.email.message : "hidden"}
                      </span>
                    </div>
                  </div>

                  <h3 className="text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                    Otros datos
                  </h3>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Tipo Trabajador
                      </label>
                      <select
                        {...register("tipoTrabajadorId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          Seleccione tipo trabajador
                        </option>
                        {tipoTrabajadores.map((tipoTrabajador) => (
                          <option
                            value={tipoTrabajador.id}
                            key={tipoTrabajador.id}
                          >
                            {tipoTrabajador.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.tipoTrabajadorId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.tipoTrabajadorId
                          ? errors.tipoTrabajadorId.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="w-full md:w-6/12 md:px-3 mb-7 md:mb-4 flex items-center">
                      <div className="flex flex-wrap">
                        <span className="tracking-wide dark:text-white text-hercules-light-font-secondary text-14 font-bold my-auto mr-5 md:mr-3">
                          Solicita subsidio trabajador joven
                        </span>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            {...register("subsidioTrabajadorJoven")}
                            type="checkbox"
                            className="sr-only peer"
                            readOnly
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-7/12 md:pr-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Tipo Cotizante
                      </label>
                      <select
                        {...register("tipoCotizanteId", {
                          required: { value: true, message: "Obligatorio" },
                          onChange: (e) => {
                            if (e.target.value === "3" || e.target.value === 3 || e.target.value === "5" || e.target.value === 5) {
                              setValue("regimenPrevisionalId", "3");
                            } else {
                              setValue("regimenPrevisionalId", "");
                            }
                          },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          Seleccione tipo cotizante
                        </option>
                        {tipoCotizantes.map((tipoCotizante) => (
                          <option
                            value={tipoCotizante.id}
                            key={tipoCotizante.id}
                          >
                            {tipoCotizante.glosa}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.tipoCotizanteId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.tipoCotizanteId
                          ? errors.tipoCotizanteId.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Régimen Previsional
                      </label>
                      <select
                        {...register("regimenPrevisionalId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        disabled={watch("tipoCotizanteId") === "3" || watch("tipoCotizanteId") === 3 || watch("tipoCotizanteId") === "5" || watch("tipoCotizanteId") === 5}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          Seleccione régimen previsional
                        </option>
                        {regimenPrevisionales.map((regimenPrevisional) => (
                          <option
                            value={regimenPrevisional.id}
                            key={regimenPrevisional.id}
                          >
                            {regimenPrevisional.glosa}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.regimenPrevisionalId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.regimenPrevisionalId
                          ? errors.regimenPrevisionalId.message
                          : "hidden"}
                      </span>
                    </div>
                  </div>

                  {watch("regimenPrevisionalId") === "2" ? (
                    <div className="md:flex md:flex-wrap">
                      <div className="div-Inputs-EditarHaber md:w-5/12 md:pr-3">
                        <label
                          className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Ex caja y régimen imp.
                        </label>
                        <select
                          {...register("cajaExRegimenId", {
                            validate: (value) => {
                              if (watch("regimenPrevisionalId") === "2") {
                                return value !== "" || "Obligatorio";
                              }
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option value="" hidden>
                            Seleccione ex caja y régimen imp.
                          </option>
                          {cajasExRegimen.map((cajaExRegimen) => (
                            <option
                              value={cajaExRegimen.id}
                              key={cajaExRegimen.id}
                            >
                              {cajaExRegimen.glosa}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.cajaExRegimenId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.cajaExRegimenId
                            ? errors.cajaExRegimenId.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Caja ex régimen desahucio
                        </label>
                        <select
                          {...register("cajaExRegimenDesahucioId", {
                            validate: (value) => {
                              if (watch("regimenPrevisionalId") === "2") {
                                return value !== "" || "Obligatorio";
                              }
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option value="" hidden>
                            Seleccione caja ex régimen desahucio
                          </option>
                          {cajasExRegimenDesahucio.map(
                            (cajaExRegimenDesahucio) => (
                              <option
                                value={cajaExRegimenDesahucio.id}
                                key={cajaExRegimenDesahucio.id}
                              >
                                {cajaExRegimenDesahucio.glosa}
                              </option>
                            )
                          )}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.cajaExRegimenDesahucioId &&
                            "opacity-0 invisible"
                          }`}
                        >
                          {errors.cajaExRegimenDesahucioId
                            ? errors.cajaExRegimenDesahucioId.message
                            : "hidden"}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <div className="div-Inputs-EditarHaber md:w-3/12 mb-9 md:mb-11">
                    <label
                      className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Fecha afiliación AFP/INP
                    </label>
                    <input
                      {...register("fechaAfiliacionAfp")}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="date"
                    />
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-4/12 md:pr-3 mb-7 md:mb-9 flex items-center">
                      <div className="flex flex-wrap">
                        <span className="tracking-wide dark:text-white text-hercules-light-font-secondary text-14 font-bold my-auto mr-5 md:mr-3">
                          Habilitar Feriado Progresivo
                        </span>

                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            {...register("feriadoProgresivo")}
                            type="checkbox"
                            className="sr-only peer"
                            readOnly
                          />

                          <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                        </label>
                      </div>
                    </div>

                    {watch("feriadoProgresivo") == true ? (
                      <div className="w-full md:w-3/12 mb-9">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Fecha Feriado Progresivo
                        </label>

                        <input
                          {...register("fechaFeriadoProgresivo", {
                            required: {
                              value:
                                watch("feriadoProgresivo") == true
                                  ? true
                                  : false,
                              message: "Obligatorio",
                            },
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="date"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.fechaFeriadoProgresivo &&
                            "opacity-0 invisible"
                          }`}
                        >
                          {errors.fechaFeriadoProgresivo
                            ? errors.fechaFeriadoProgresivo.message
                            : "hidden"}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="div-Inputs-EditarHaber md:w-3/12">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Grupo Sindicato
                    </label>
                    <select
                      {...register("grupoSindicatoId", {
                        required: { value: true, message: "Obligatorio" },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                    >
                      <option value="" hidden>
                        Seleccione sindicato
                      </option>
                      {sindicatos.map((sindicato) => (
                        <option value={sindicato.id} key={sindicato.id}>
                          {sindicato.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.grupoSindicatoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.grupoSindicatoId
                        ? errors.grupoSindicatoId.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="flex flex-wrap mb-8 md:mb-11">
                    <div className="w-5/12 md:w-2/12">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Seguro de Cesantía
                      </label>
                      <select
                        {...register("seguroCesantia")}
                        disabled={seguroCesantiaHabilitado}
                        name="seguroCesantia"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                    <button
                      onClick={() => setShowSeguroCesantiaAlert(true)}
                      className="ml-3 mt-7 hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-sm px-4 py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                      type="button"
                    >
                      Habilitar
                    </button>
                    {showSeguroCesantiaAlert ? (
                      <SeguroCesantiaPopUp
                        enableSelect={() => setSeguroCesantiaHabilitado(false)}
                        onCloseAlert={() => setShowSeguroCesantiaAlert(false)}
                      />
                    ) : null}
                  </div>

                  <div className="flex flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Forma de Pago
                      </label>
                      <select
                        {...register("formaDePago", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          Seleccione forma de pago
                        </option>
                        {formasDePago.map((formaDePago) => (
                          <option
                            value={formaDePago.id - 1}
                            key={formaDePago.id}
                          >
                            {formaDePago.nombre}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.formaDePago && "opacity-0 invisible"
                        }`}
                      >
                        {errors.formaDePago
                          ? errors.formaDePago.message
                          : "hidden"}
                      </span>
                    </div>
                    {watch("formaDePago") == 2 ? (
                      <>
                        <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                          <label
                            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Banco destino
                          </label>
                          <select
                            {...register("bancoDestinoId", {
                              validate: (value) => {
                                if (
                                  watch("formaDePago") === "2" &&
                                  value === ""
                                ) {
                                  return "Obligatorio";
                                }
                              },
                            })}
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                          >
                            <option value="" hidden>
                              Seleccione banco destino
                            </option>
                            {bancos.map((banco) => (
                              <option value={banco.id} key={banco.id}>
                                {banco.nombre}
                              </option>
                            ))}
                          </select>
                          <span
                            className={`text-red-500 text-xs italic ${
                              !errors.bancoDestinoId && "opacity-0 invisible"
                            }`}
                          >
                            {errors.bancoDestinoId
                              ? errors.bancoDestinoId.message
                              : "hidden"}
                          </span>
                        </div>

                        <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                          <label
                            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Tipo de Cuenta
                          </label>
                          <select
                            {...register("tipoCuentaId", {
                              validate: (value) => {
                                if (
                                  watch("formaDePago") === "2" &&
                                  value === ""
                                ) {
                                  return "Obligatorio";
                                }
                              },
                            })}
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                          >
                            <option value="" hidden>
                              Seleccione tipo de cuenta
                            </option>
                            {tiposCuenta.map((tipoCuenta) => (
                              <option value={tipoCuenta.id} key={tipoCuenta.id}>
                                {tipoCuenta.nombre}
                              </option>
                            ))}
                          </select>
                          <span
                            className={`text-red-500 text-xs italic ${
                              !errors.tipoCuentaId && "opacity-0 invisible"
                            }`}
                          >
                            {errors.tipoCuentaId
                              ? errors.tipoCuentaId.message
                              : "hidden"}
                          </span>
                        </div>

                        <div className="div-Inputs-EditarHaber md:w-2/12 md:pl-3">
                          <label
                            className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                            htmlFor="grid-first-name"
                          >
                            Número de Cuenta
                          </label>
                          <input
                            {...register("numeroCuenta", {
                              validate: (value) => {
                                if (
                                  watch("formaDePago") === "2" &&
                                  value === ""
                                ) {
                                  return "Obligatorio";
                                }
                              },
                            })}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            type="text"
                          />
                          <span
                            className={`text-red-500 text-xs italic ${
                              !errors.numeroCuenta && "opacity-0 invisible"
                            }`}
                          >
                            {errors.numeroCuenta
                              ? errors.numeroCuenta.message
                              : "hidden"}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="font-semibold mb-3">
                    <h3 className="text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                      Libro de remuneraciones electrónico:
                    </h3>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label
                        className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Tipo impuesto a la renta (cod. 1170)
                      </label>
                      <select
                        {...register("tipoImpuestoRentaId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          Seleccionar tipo impuesto renta
                        </option>
                        {tiposImpuestoRenta.map((tipoImpuestoRenta) => (
                          <option
                            value={tipoImpuestoRenta.id}
                            key={tipoImpuestoRenta.id}
                          >
                            {tipoImpuestoRenta.glosa}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.tipoImpuestoRentaId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.tipoImpuestoRentaId
                          ? errors.tipoImpuestoRentaId.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Persona con discapacidad (cod. 1108)
                      </label>
                      <select
                        {...register("personaConDiscapacidadId", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        name="personaConDiscapacidadId"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option value="" hidden>
                          Seleccionar tipo discapacidad
                        </option>
                        {personasConDiscapacidad.map(
                          (personaConDiscapacidad) => (
                            <option
                              value={personaConDiscapacidad.id}
                              key={personaConDiscapacidad.id}
                            >
                              {personaConDiscapacidad.glosa}
                            </option>
                          )
                        )}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.personaConDiscapacidadId &&
                          "opacity-0 invisible"
                        }`}
                      >
                        {errors.personaConDiscapacidadId
                          ? errors.personaConDiscapacidadId.message
                          : "hidden"}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-wrap mb-3">
                    <div className="md:w-4/12 flex flex-wrap md:pl-3 mb-6 md:mb-0">
                      <div className="w-full flex md:items-center">
                        <span className="mr-6 tracking-wide dark:text-white text-hercules-light-font-secondary text-14 font-bold my-auto">
                          Pensionado por vejez (cod. 1109)
                        </span>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            {...register("pensionadoVejez")}
                            type="checkbox"
                            className="sr-only peer"
                            readOnly
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                        </label>
                      </div>
                    </div>

                    <div className="md:w-6/12 flex flex-wrap md:pl-3">
                      <div className="w-full flex md:items-center">
                        <span className="mr-6 tracking-wide dark:text-white text-hercules-light-font-secondary text-14 font-bold my-auto">
                          Técnico extranjero excento cot. prev. (cod. 1146)
                        </span>
                        <label className="inline-flex relative md:items-center cursor-pointer">
                          <input
                            {...register("tecnicoExtranjero")}
                            type="checkbox"
                            className="sr-only peer"
                            readOnly
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  reset({});
                  onClose();
                }}
              >
                Cerrar
              </button>
              <button
                disabled={!loadingModal || !botonGuardarHabilitado}
                className={`${
                  !loadingModal || !botonGuardarHabilitado
                    ? "bg-gray-500"
                    : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 "
                } text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                type="submit"
              >
                Guardar y continuar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </form>
  );
}
