import axios from "axios";
import { axiosPrivate } from "../../common/axiosPrivate";

const REACT_APP_API_HERCULES_CENTRAL =
  process.env.REACT_APP_API_HERCULES_CENTRAL;

const REACT_APP_API_HERCULES_SERVICE =
  process.env.REACT_APP_API_HERCULES_SERVICE;

  function getAuhtToken() {
    return localStorage.getItem("token-user");
  }

// const tokenRut = localStorage.getItem("token-rut");
// const token = tokenRut.slice(0, 8);
// const tokenUser = localStorage.getItem("token-user");

// const Authorization = {
//   headers: {
//     Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
//   },
// };

export function showLoader() {
  return {
    type: "SHOW_LOADER",
  };
}

export function hideLoader() {
  return {
    type: "HIDE_LOADER",
  };
}

export function getHolding() {
  return async function (dispatch) {
    try {
      var info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_CENTRAL}/api/v1/central/holdings`
      );
      return dispatch({
        type: "GET_HOLDINGS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function InfoHolding(rutHolding) {
  return async function (dispatch) {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
        },
      };

      const data = {
        rutHolding,
      };
      var info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/holding`,
        data,
        Authorization
      );

      return dispatch({
        type: "INFO_HOLDING",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getEmpresas() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const empresas = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/`,
        Authorization
      );
      return dispatch({
        type: "GET_EMPRESAS",
        payload: empresas.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCentrosCostosByEmpresaId(empresaId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const centrosDeCosto = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/${empresaId}/centros-de-costo`,
        Authorization
      );
      return dispatch({
        type: "GET_CENTRO_COSTO_EMPRESA",
        payload: centrosDeCosto.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}



export function getEmpresasv2() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const empresas = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/listaempresasv2`,
        Authorization
      );
      return dispatch({
        type: "GET_EMPRESAS",
        payload: empresas.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getListaEmpresasTabla() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    // console.log("Va al try y catch")
    try {
      const empresas = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/`,
        Authorization
      );
      return dispatch({
        type: "GET_LISTA_EMPRESAS",
        payload: empresas.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function postPeriodoEmpresas(idEmpresa, estado) {
  return async function (dispatch) {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
        },
      };

      const data = {
        idEmpresa,
        estado,
      };
      const periodos = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/periodosempresa`,
        data,
        Authorization
      );
      return dispatch({
        type: "POST_PERIODO_EMPRESAS",
        payload: periodos.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getRoles() {
  return async function (dispatch) {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
        },
      };

      var info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/rolesvisibles`,
        Authorization
      );
      return dispatch({
        type: "GET_ROLES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getRolesActivos() {
  return async function (dispatch) {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
        },
      };

      var info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/activos`,
        Authorization
      );
      return dispatch({
        type: "GET_ROLES_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function postRolMenu(id) {
  return async function (dispatch) {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
        },
      };
      const data = {
        id,
      };

      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/menusrol`,
        data,
        Authorization
      );
      return dispatch({
        type: "POST_ROL_MENU",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

// Pantalla 9

export function listarUsuarios() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const lista = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/usuarios/`,
        Authorization
      );
      return dispatch({
        type: "LISTAR_USUARIOS",
        payload: lista.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function filtroUsuarios(filtro) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/usuarios/userfilter?nombre=${filtro.nombre}&user=${filtro.usuario}&activo=${filtro.activo}&nombreRol=${filtro.rol}`,
        Authorization
      );
      console.log(
        "ruta a despachar",
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/usuarios/userfilter?nombre=${filtro.nombre}&user=${filtro.usuario}&activo=${filtro.activo}&nombreRol=${filtro.rol}`
      );
      const mapeo = info.data.map((el) => {
        const id = el.id;
        const user = el.user;
        const nombre = el.nombre;
        const roles = el.roles;
        const activo = el.activo;
        const obj = {
          id,
          user,
          nombre,
          roles,
          activo,
        };
        return obj;
      });

      return dispatch({
        type: "FILTRAR_USUARIOS",
        payload: mapeo,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

//Pantalla 11

export function getRepLegales() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/representantelegal/representantes`,
        Authorization
      );
      return dispatch({
        type: "GET_REP",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getRepLegalesActivos() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/representantelegal/activos`,
        Authorization
      );
      return dispatch({
        type: "GET_REP_LEGALES_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

// Pantalla 12
export function getEmpresasTabla() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/listaempresas`,
        Authorization
      );
      return dispatch({
        type: "GET_EMPRESAS_TABLA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

// export function filterEmp(info) {
//   const payloadRazonSocial = info.razonSocial;
//   const payloadRut = info.rut;
//   const payloadGiro = info.giro;
//   const payloadRepLegal = info.nombreRepLegal;
//   const payload = `${payloadRut},${payloadRazonSocial},${payloadGiro},${payloadRepLegal}`;
//   return async function (dispatch) {
//     try {
//       return dispatch({
//         type: "FILTER_EMP",
//         payload,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// }

// Pantalla 13
export function getNacionalidades() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/ubicaciones/nacionalidades/`,
        Authorization
      );
      return dispatch({
        type: "GET_NACIONALIDADES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getPaises() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/ubicaciones/paises/`,
        Authorization
      );
      return dispatch({
        type: "GET_PAISES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getRegiones(idPais) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/ubicaciones/regionespais/${idPais}`,
        Authorization
      );
      return dispatch({
        type: "GET_REGIONES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getComunas(idRegion) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/ubicaciones/comunasregion/${idRegion}`,
        Authorization
      );
      return dispatch({
        type: "GET_COMUNAS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCiudades(idComuna) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/ubicaciones/ciudadescomuna/${idComuna}`,
        Authorization
      );
      return dispatch({
        type: "GET_CIUDADES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCcaf() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/listaccaf`,
        Authorization
      );
      return dispatch({
        type: "GET_CCAF",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getMutual() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/listamutuales`,
        Authorization
      );
      return dispatch({
        type: "GET_MUTUALES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function ccafEmpresa(empresaId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const data = {
        empresaId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/tasaempresaccaf`,
        data,
        Authorization
      );
      return dispatch({
        type: "CCAF_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function mutualEmpresa(empresaId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const data = {
        empresaId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/tasaempresasmutual`,
        data,
        Authorization
      );
      return dispatch({
        type: "MUTUAL_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

// Pantalla 16

export function getTrabajadores() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listatrabajadores`,
        Authorization
      );
      return dispatch({
        type: "GET_TRABAJADORES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

// Pantalla 17 - Editar Trabajador

export function obtenerTrabajador(trabajadorId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const data = {
        trabajadorId,
      };

      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/trabajador`,
        data,
        Authorization
      );
      dispatch({
        type: "OBTENER_TRABAJADOR",
        payload: info.data
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export const limpiarTrabajador = () => {
  return {
    type: 'LIMPIAR_TRABAJADOR'
  };
};


export function datosTrabajador() {
  return async function (dispatch) {

    function getAuhtToken() {
      return localStorage.getItem("token-user");
    }
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = getAuhtToken();

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/datospantallatrabajador`,
        Authorization
      );
      dispatch({
        type: "DATOS_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function afpTrabajador(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const data = {
        trabajadorId,
      };

      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/afptrabajadorperiodos`,
        data,
        Authorization
      );
      dispatch({
        type: "AFP_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export function listaApvPeriodo(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const data = {
        trabajadorId,
      };

      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listaapvperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "LISTA_APV",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

//Pantalla 18 

export function listaAfp(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/traerafps`,
        Authorization
      );
      dispatch({
        type: "LISTA_AFP",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export function listaPeriodos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/periodos/listaperiodos`,
        Authorization
      );
      dispatch({
        type: "LISTA_PERIODOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export function listaPeriodosHastaActual(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/periodos/periodosHastaActual`,
        Authorization
      );
      dispatch({
        type: "LISTA_PERIODOS_HASTA_ACTUAL",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}


export function obtenerTasaAfpPeriodo(afpId, periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const data = {
        afpId,
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/tasaperiodoafp`,
        data,
        Authorization
      );
      dispatch({
        type: "TASA_AFP_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

// Pantalla 19

export function listaSalud(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listaintitucionessalud`,
        Authorization
      );
      dispatch({
        type: "LISTA_SALUD",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export function obtenerTasaSaludPeriodo(saludId, periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        saludId,
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/tasaperiodosalud`,
        data,
        Authorization
      );
      dispatch({
        type: "TASA_SALUD_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function tablaSalud(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        trabajadorId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/datospantallatrabajadorsalud`,
        data,
        Authorization
      );
      dispatch({
        type: "TABLA_SALUD",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

//Pantalla 20

export function datosTrabajadorCFamiliar(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const data = {
        trabajadorId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/datostrabajadorcargas`,
        data,
        Authorization
      );
      dispatch({
        type: "DATOS_TRABAJADOR_CARGA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function listaTramosCFamiliar(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/tramoscargasfamiliares`,
        Authorization
      );
      dispatch({
        type: "TRAMOS_CARGA_FAMILIAR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerListaTramosCFamiliar(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/tramoscargasfamiliaresperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "TASA_CARGA_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

//Pantalla 21
export function getJornadas(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/jornadas`,
      Authorization
      );
      dispatch({
        type: "GET_JORNADAS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getTipoTrabajador(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/tipotrabajadores`,
      Authorization
      );
      dispatch({
        type: "GET_TIPO_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getSueldoMinimo(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sueldominimo`,
      Authorization
      );
      dispatch({
        type: "GET_SUELDO_MINIMO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getSueldoMinimoPeriodo(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    const data = {
      periodoId: periodoId,
    }

    try {
      const info = await axiosPrivate.post(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sueldominimoperiodo`,
      data,
      Authorization,
      );
      dispatch({
        type: "GET_SUELDO_MINIMO_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}


//Pantalla 21B

export function getHsExtra(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listatiposhorasextras`,
      Authorization
      );
      dispatch({
        type: "GET_HS_EXTRA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getHsExtraActivadas(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listatiposhorasextrasactivos`,
      Authorization
      );
      dispatch({
        type: "GET_HS_EXTRA_ACTIVADAS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

//Pantalla 22

export function getHaberes(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listahaberes`,
      Authorization
      );
      dispatch({
        type: "GET_HABERES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getHaberesActivos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/haberes/activos`,
      Authorization
      );
      dispatch({
        type: "GET_HABERES_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getDescuentosBasicos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/basicos`,
      Authorization
      );
      dispatch({
        type: "GET_DESCUENTOS_BASICOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}


export function getHaberesDT(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listahaberesdt`,
      Authorization
      );
      dispatch({
        type: "GET_HABERESDT",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

//Pantalla 24 

export function getDescuentos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listadescuentos`,
      Authorization
      );
      dispatch({
        type: "GET_DESCUENTOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getDescuentosActivos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/activos/editables`,
      Authorization
      );
      dispatch({
        type: "GET_DESCUENTOS_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getDescuentosDT(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listadescuentosdt`,
      Authorization
      );
      dispatch({
        type: "GET_DESCUENTOSDT",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

// Pantalla 26 - Mantenedor de Contratos

export function getContratos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/`,
      Authorization
      );
      dispatch({
        type: "GET_CONTRATOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getContratosResumen(empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/empresa/${empresaId}/resumen`,
      Authorization
      );
      dispatch({
        type: "GET_CONTRATOS_RESUMEN",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getTrabajadoresSinContratos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = getAuhtToken();
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sincontrato`,
      Authorization
      );
      dispatch({
        type: "GET_TRABAJADORES_SIN_CONTRATOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getCargos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/cargos`,
      Authorization
      );
      dispatch({
        type: "GET_CARGOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getCentroCostos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/centrosdecostos`,
      Authorization
      );
      dispatch({
        type: "GET_CENTRO_COSTOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getSecciones(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/secciones`,
      Authorization
      );
      dispatch({
        type: "GET_SECCIONES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function listaSaludPeriodo(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/saludtasaperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "LISTA_SALUD_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}


// Pantalla 29 Indicadores Previsionales

export function parametrosPeriodo(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/parametrosperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "PARAMETROS_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}
export function limpiarInfoIndicadores(){
  return {
    type: 'LIMPIAR_INDICADORES_PREVISIONALES'
  };
};

export function listaAFPPeriodosTasa(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/listaafpperiodostasa`,
        data,
        Authorization
      );
      dispatch({
        type: "LISTA_AFP_PERIODOS_TASA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function rentasTopeImponible(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/topeimponible`,
        data,
        Authorization
      );
      dispatch({
        type: "RTAS_TOPE_IMPONIBLE",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function rentasMinimasPeriodo(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/rentasminimasperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "RTAS_MIN_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function topeAPV(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/topeapv`,
        data,
        Authorization
      );
      dispatch({
        type: "TOPE_APV",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function trabajosPesados(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/trabajospesados`,
      Authorization
      );
      dispatch({
        type: "TRABAJOS_PESADOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function afcPeriodo(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/afcperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "AFC_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function impuestosRenta(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/impuestosegundacategoria`,
        data,
        Authorization
      );
      dispatch({
        type: "IMPUESTOS_RENTA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function tramoAsignacionFamiliar(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/tramoscargasfamiliaresperiodo`,
        data,
        Authorization
      );
      dispatch({
        type: "TRAMO_ASIGNACION_FAMILIAR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getBancos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/bancos`,
      Authorization
      );
      dispatch({
        type: "GET_BANCOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getCausalesTermContrato(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/causalesterminocontrato`,
      Authorization
      );
      dispatch({
        type: "GET_CAUSALES_TERM_CONTRATO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getListaSindicato(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sindicato`,
      Authorization
      );
      dispatch({
        type: "GET_SINDICATOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getListaSindicatoActivosVisibles(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sindicatos/activos/visibles`,
      Authorization
      );
      dispatch({
        type: "GET_SINDICATOS_ACTIVOS_VISIBLES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getListaSindicatosVisibles(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sindicatos/visibles`,
      Authorization
      );
      dispatch({
        type: "GET_SINDICATOS_VISIBLES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getSindicatosActivos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/sindicatos/activos`,
      Authorization
      );
      dispatch({
        type: "SINDICATOS_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function movimientosPersonal(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/movimientosdepersonal`,
      Authorization
      );
      dispatch({
        type: "MOVIMIENTOS_PERSONAL",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function pagadoresSubsidio(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/pagadoresSubsidio`,
      Authorization
      );
      dispatch({
        type: "PAGADORES_SUBSIDIO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function ips(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/cajasexregimen`,
      Authorization
      );
      dispatch({
        type: "IPS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function factoresActualizacion(anyo){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        anyo,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/factoresactualizacion`,
        data,
        Authorization
      );
      dispatch({
        type: "FACTORES_ACTUALIZACION",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function contratosMovTrabLista(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/movimientosTrabajadores/lista`,
        data,
        Authorization
      );
      dispatch({
        type: "CONTRATOS_MOV_TRAB_LISTA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerContratosSemanaCorrida(periodoId, mes, anyo){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
        mes,
        anyo
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/semanaCorrida/lista`,
        data,
        Authorization
      );
      dispatch({
        type: "CONTRATOS_SEM_CORRIDA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerContratosSemanaCorridaPorEmpresa(periodoId, mes, anyo, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        empresaId,
        periodoId,
        mes,
        anyo
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/empresa/semanaCorrida/lista`,
        data,
        Authorization
      );
      dispatch({
        type: "CONTRATOS_SEM_CORRIDA_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function trabajadoresContratoVigente(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/obtenerTrabajadoresContratoVigente`,
      Authorization
      );
      dispatch({
        type: "TRABAJADORES_CONTRATO_VIGENTE",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function descuentosEnCuotasOProgramados(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/obtenerDescuentosCuotas`,
      Authorization
      );
      dispatch({
        type: "DESCUENTOS_CUOTAS_PROGRAMADOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function obtenerPeriodosAnteriores(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
        orden: "DESC"
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/periodos/periodosPorId`,
        data,
        Authorization
      );
      dispatch({
        type: "PERIODOS_ANTERIORES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerLiquidacionesPorPeriodoCentroCosto(periodoId, centroCostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
        centroCostoId
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/obtenerLiquidacionesPorPeriodoCentroCosto`,
        data,
        Authorization
      );
      dispatch({
        type: "LIQUIDACION_PERIODO_CCOSTO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerTrabajadoresCalculoLiquidaciones(periodoId, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const data = {
        periodoId,
        empresaId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/obtenerTrabajadoresCalculoLiquidaciones`,
        data,
        Authorization
      );
      dispatch({
        type: "TRABAJADORES_CALCULO_LIQUIDACIONES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerDescuentosProgramadosPorPeriodoCentroCosto(periodoId, centroCostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      console.log("periodoId", periodoId, "centroCostoId", centroCostoId)
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/programados/periodo/${periodoId}/centro-costo/${centroCostoId}`,
        Authorization
      );
      dispatch({
        type: "DESCUENTO_PERIODO_CCOSTO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerRemuneracionesMensual(periodoId, centroCostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/liquidaciones/libro-remuneraciones/mensual/periodo/${periodoId}/centroCosto/${centroCostoId}`,
        Authorization
      );
      dispatch({
        type: "REMUNERACIONES_MENSUAL",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerPeriodoId(mes, anyo){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/periodos/mes/${mes}/anyo/${anyo}`,
        Authorization
      );
      dispatch({
        type: "OBTENER_PERIODO_ID",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerCausalesFiniquito(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/finiquitos/causales-termino-contrato`,
        Authorization
      );
      dispatch({
        type: "CAUSALES_FINIQUITO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerFiniquitos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/finiquitos`,
        Authorization
      );
      dispatch({
        type: "OBTENER_FINIQUITOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function movimientosTrabajadorPeriodo(periodoId, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/movimientos-trabajador/periodo/${periodoId}/empresa/${empresaId}`,
        Authorization
      );
      dispatch({
        type: "MOVIMIENTOS_TRABAJADOR_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

// export function obtenerLiquidacionesPorCentroCostoPeriodo(periodoId, centroCostoId){
//   return async function(dispatch){
//     const tokenRut = localStorage.getItem("token-rut");
//     const token = tokenRut.slice(0, 8);
//     const tokenUser = localStorage.getItem("token-user");
//     const Authorization = {
//       headers: {
//         Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
//       },
//     };
    
//     try {
//       const info = await axiosPrivate.get(
//         `${REACT_APP_API_HERCULES_SERVICE}/api/v1/liquidaciones/periodo/${periodoId}/centro-costo/${centroCostoId}`,
//         Authorization
//       );
//       dispatch({
//         type: "LIQUIDACION_POR_CENTROCOSTO_PERIODO",
//         payload: info.data,
//       });
//     } catch (error) {
//       console.log(error)
//     }
//     }
// }


// API Para el inicio/dashboard

export function totalTrabajadores(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/total-trabajadores`,
        Authorization
      );
      dispatch({
        type: "TOTAL_TRABAJADORES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function contratosVigentes(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/total-contratos/vigentes`,
        Authorization
      );
      dispatch({
        type: "CONTRATOS_VIGENTES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}
export function contratosVencidos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/total-contratos/vencidos`,
        Authorization
      );
      dispatch({
        type: "CONTRATOS_VENCIDOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}
export function contratosFiniquitados(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/total-contratos/finiquitados`,
        Authorization
      );
      dispatch({
        type: "CONTRATOS_FINIQUITADOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}
export function totalLiquidacionesPeriodo(periodoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/liquidaciones/total/${periodoId}`,
        Authorization
      );
      dispatch({
        type: "TOTAL_LIQUIDACIONES_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function totalLiquidacionesUltimos6Meses(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/liquidaciones/total/6meses`,
        Authorization
      );
      dispatch({
        type: "TOTAL_LIQUIDACIONES_ULTIMOS_MESES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function totalAportesUltimos6Meses(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/liquidaciones/aporte-empleador/6meses`,
        Authorization
      );
      dispatch({
        type: "TOTAL_APORTES_ULTIMOS_MESES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function totalHsExtrasUltimos6Meses(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/dashboard/liquidaciones/horas-extras/6meses`,
        Authorization
      );
      dispatch({
        type: "TOTAL_HS_EXTRAS_ULTIMOS_MESES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getListaTrabajadoresActivos(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/activos`,
        Authorization
      );
      dispatch({
        type: "LISTA_TRABAJADORES_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}


export function getInfoSolicitudVacacionesTabla(ccostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/centro-costo/${ccostoId}/trabajadores`,
        Authorization
      );
      dispatch({
        type: "SOLICITUD_VACACIONES_TABLA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getPeriodosVacacionesPorTrabajador(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    if(trabajadorId){
      try {
        const info = await axiosPrivate(
          `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/periodo/trabajador/${trabajadorId}`,
          Authorization
        );
        dispatch({
          type: "PERIODOS_VACACIONES_TRABAJADOR",
          payload: info.data,
        });
      } catch (error) {
        console.log(error)
      }  
    }
    }
}


export function getRegistrosVacacionesCCosto(ccostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/centro-costo/${ccostoId}/registros`,
        Authorization
      );
      dispatch({
        type: "REGISTROS_VACACIONES_CCOSTO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getSolicitudVacacionesCCosto(ccostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/centro-costo/${ccostoId}/solicitudes`,
        Authorization
      );
      dispatch({
        type: "SOLICITUDES_VACACIONES_CCOSTO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getSolicitudesAprobadasyPendientes(ccostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/centro-costo/${ccostoId}/solicitudes/pendientes-aprobados`,
        Authorization
      );
      dispatch({
        type: "SOLICITUDES_APROBADAS_Y_PENDIENTES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getRegistrosVacacionesTrabajador(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/trabajador/${trabajadorId}/registros`,
        Authorization
      );
      dispatch({
        type: "REGISTROS_VACACIONES_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getSolicitudesVacacionesTrabajador(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/trabajador/${trabajadorId}/solicitudes`,
        Authorization
      );
      dispatch({
        type: "SOLICITUDES_VACACIONES_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getFeriadosCentroCosto(centroCostoId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/centro-costo/${centroCostoId}/feriados/2024`,
        Authorization
      );
      dispatch({
        type: "FERIADOS_CENTRO_COSTO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getModulosHolding(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    const data = {
      rut: tokenRut,
    };

    try {
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_CENTRAL}/api/v1/central/holdings/modulos`,
        data,
        Authorization
      );
      dispatch({
        type: "MODULOS_HOLDING",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getCCostosUsuario(usuarioId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/usuarios/${usuarioId}/centros-costos-posibles`,
        Authorization
      );
      dispatch({
        type: "LISTADO_CCOSTO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function modulosDisponiblesUsuario(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const data = {
        rut: tokenRut,
      };  
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_CENTRAL}/api/v1/central/holdings/modulos`,
        data,
        Authorization
      );
      dispatch({
        type: "LISTADO_MODULOS_DISPONIBLES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function dashboardVacaciones() {
  return async function (dispatch) {
    const centroCostoId = localStorage.getItem("centroCostoId");
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/dashboard/${centroCostoId}`,
        Authorization
      );
      return dispatch({
        type: "DASHBOARD_VACACIONES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getPeriodosVacacionesDisponiblesPorTrabajador(trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    if(trabajadorId){
      try {
        const info = await axiosPrivate(
          `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/periodo/disponibles/trabajador/${trabajadorId}`,
          Authorization
        );
        dispatch({
          type: "PERIODOS_DISPONIBLES_VACACIONES_TRABAJADOR",
          payload: info.data,
        });
      } catch (error) {
        console.log(error)
      }  
    }
    }
}

export function getTrabajadoresEmpresa(empresaId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/empresa/${empresaId}`,
        Authorization
      );
      return dispatch({
        type: "GET_TRABAJADORES_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function contratosPorEmpresa(empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.post(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/contratos/empresa/${empresaId}/estado/1`,
      {},
      Authorization
      );
      dispatch({
        type: "CONTRATOS_POR_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getTrabajadoresNominaBancaria(centroCostoId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const periodoId = localStorage.getItem("periodoId");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/liquidaciones/trabajadores/pago/nomina-bancaria/centro-costo/${centroCostoId}/periodo/${periodoId}`,
        Authorization
      );
      return dispatch({
        type: "GET_TRABAJADORES_NOMINA_BANCARIA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function listaTrabajadoresParaCambioJornada(periodoId, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/movimientos-trabajador/jornadas/periodo/${periodoId}/empresa/${empresaId}`,
        Authorization
      );
      dispatch({
        type: "LISTA_TRABAJADORES_CAMBIO_JORNADA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerRegistrosCargasFamiliaresPeriodo(periodoId, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/cargas-familiares/periodo/${periodoId}/empresa/${empresaId}`,
        Authorization
      );
      dispatch({
        type: "REGISTROS_CARGAS_FAMILIARES_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerRegistrosCreditosCCAFPeriodo(periodoId, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/descuentos/ccaf/periodo/${periodoId}/empresa/${empresaId}`,
        Authorization
      );
      dispatch({
        type: "REGISTROS_CREDITOS_CCAF_PERIODO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getGratificacionByEmpresa(empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/${empresaId}/gratificacion`,
      Authorization
      );
      dispatch({
        type: "GET_GRATIFICACION_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getGratificacionesLegales() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/haberes/gratificaciones`,
        Authorization
      );
      return dispatch({
        type: "GET_GRATIFICACIONES",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getEventos() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/eventos`,
        Authorization
      );
      return dispatch({
        type: "GET_EVENTOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getEventosActivos() {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/eventos/activos`,
        Authorization
      );
      return dispatch({
        type: "GET_EVENTOS_ACTIVOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getEventosTrabajador(idTrabajador) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/${idTrabajador}/eventos`,
        Authorization
      );
      return dispatch({
        type: "GET_EVENTOS_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getHistorialDescuentosFijosTrabajador(idTrabajador){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/fijo/trabajador/${idTrabajador}`,
      Authorization
      );
      dispatch({
        type: "GET_DESCUENTOS_FIJOS_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function getHistorialDescuentosFijosTrabajadorEmpresa(idTrabajador, empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/fijo/historial/trabajador/${idTrabajador}/empresa/${empresaId}`,
      Authorization
      );
      dispatch({
        type: "GET_HISTORIAL_DESCUENTOS_TRABAJADOR_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export function obtenerHorasExtrasCanjeadasEmpresa(empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/movimientos-trabajador/canjear-horas-extras/empresa/${empresaId}`,
        Authorization
      );
      dispatch({
        type: "OBTENER_HORAS_EXTRAS_CANJEADAS_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function administrarCanjesHorasExtraTrabajador (empresaId, trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/movimientos-trabajador/canjear-horas-extras/empresa/${empresaId}/trabajador/${trabajadorId}`,
        Authorization
      );
      dispatch({
        type: "ADMINISTRAR_CANJES_HORAS_EXTRA_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerHistorialTrabajadorCanjeHorasExtraUtilizadas (empresaId, trabajadorId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/movimientos-trabajador/canjear-horas-extras/empresa/${empresaId}/trabajador/${trabajadorId}/historial`,
        Authorization
      );
      dispatch({
        type: "OBTENER_HISTORIAL_TRABAJADOR_CANJEHSEXTRA_UTILIZADAS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function getRegistroFamiliarTrabajador(trabajadorId) {
  return async function (dispatch) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };

    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/${trabajadorId}/registro-familiar`,
        Authorization
      );
      return dispatch({
        type: "GET_REGISTRO_FAMILIAR_TRABAJADOR",
        payload: info.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function obtenerPlantillasEmpresa(empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/plantillas/empresa/${empresaId}`,
        Authorization
      );
      dispatch({
        type: "PLANTILLAS_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerPlantillasConfiguradasEmpresa(empresaId){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/plantillas/empresa/${empresaId}/configurada`,
        Authorization
      );
      dispatch({
        type: "PLANTILLAS_CONFIGURADAS_EMPRESA",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerAtributosContrato(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/plantillas/atributos/contrato`,
        Authorization
      );
      dispatch({
        type: "ATRIBUTOS_CONTRATOS",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerAtributosAnexoContrato(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/plantillas/atributos/anexo`,
        Authorization
      );
      dispatch({
        type: "ATRIBUTOS_ANEXO_CONTRATO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function obtenerAtributosFiniquito(){
  return async function(dispatch){
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
      },
    };
    
    try {
      const info = await axiosPrivate(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/plantillas/atributos/finiquito`,
        Authorization
      );
      dispatch({
        type: "ATRIBUTOS_FINIQUITO_CONTRATO",
        payload: info.data,
      });
    } catch (error) {
      console.log(error)
    }
    }
}

export function limpiarInfoMenuEdicion(estado){
  return {
    type: 'LIMPIAR_INFO_EDICION',
    payload: estado,
  };
};

export function limpiarRepLegales(){
  return {
    type: 'LIMPIAR_REP_LEGALES',
  };
};

export function limpiarInfoModales(estado){
  return {
    type: 'LIMPIAR_INFO_MODALES',
    payload: estado
  };
};