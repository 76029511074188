import React, { useEffect, useState } from "react";
import ModalSpinner from "../../ModalSpinner";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import TablaHaberes from "./TablaHaberes";
import ColumnFilter from "../../ColumnFilter";
import AdvertenciaEliminarContratoAnexo from "./ModalAdvertenciaEliminarContratoAnexo";

export default function NuevoVerContratoAnexo({
  modalData,
  isVisible,
  onClose,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const { refreshTable, screenSize, setRowDataAnexo, contratoId } = useStateContext();
  const nombreEmpresa = localStorage.getItem("empresa");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const [afectoASemanaCorrida, setAfectoASemanaCorrida] = useState(false);
  const [haberesAnexo, setHaberesAnexo] = useState([]);
  const [tieneGratificaciones, setTieneGratificaciones] = useState(false);
  const [tieneSemanaCorrida, setTieneSemanaCorrida] = useState(false);
  const [showModalEliminar, setShowModalEliminar] = useState(false);

  const opciones = Array.from(
    { length: modalData.cantidadAnexos + 1},
    (_, index) => index + 1
  );

  useEffect(() => {
    if (modalData) {
      const estado =
        modalData.estado === 1
          ? "Vigente"
          : modalData.estado === 2
          ? "Vencido"
          : modalData.estado === 3
          ? "Finiquitado"
          : null;

      const nombreTrabajador =
        modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno;

      const rutTrabajador = UtilsFunctions.rutFormatter(
        modalData.rutTrabajador,
        { dots: true }
      );

      const articulo22 = modalData.articulo22 === true ? "Si" : "No";

      const trabajoPesado =
        modalData.trabajoPesado === 0
          ? "No"
          : modalData.trabajoPesado === 1
          ? "Trabajo menos pesado 2%"
          : modalData.trabajoPesado === 2
          ? "Trabajo pesado 4%"
          : null;

      // if (!tablaHaberesEditada) {
        if (modalData.version > 1) {
          // Es un Anexo
          const copiaHaberesAnexoOriginal = [...modalData.anexosHaberes];
          setHaberesAnexo(copiaHaberesAnexoOriginal);
          const encontrarSemanaCorrida = modalData.anexosHaberes.find(
            (e) => e.haber.haberesDt.id == "4"
          );
          if (encontrarSemanaCorrida && encontrarSemanaCorrida.haber) {
            setTieneSemanaCorrida(true);
          } else {
            setTieneSemanaCorrida(false);
          }
          const encontrarGratificacion6 = modalData.anexosHaberes.find(
            (e) => e.haber.haberesDt.id == 6
          );
          const encontrarGratificacion22 = modalData.anexosHaberes.find(
            (e) => e.haber.haberesDt.id == 22
          );
          if (encontrarGratificacion22 || encontrarGratificacion6) {
            setTieneGratificaciones(true);
          } else {
            setTieneGratificaciones(false);
          }
        } else if (modalData.version === 1) {
          // Es un Contrato
          const copiaHaberesAnexoOriginal = [...modalData.contratosHaberes];
          setHaberesAnexo(copiaHaberesAnexoOriginal);

          const encontrarSemanaCorrida = modalData.contratosHaberes.find(
            (e) => e.haber.haberesDt.id == "4"
          );
          if (encontrarSemanaCorrida && encontrarSemanaCorrida.haber) {
            setTieneSemanaCorrida(true);
          } else {
            setTieneSemanaCorrida(false);
          }
          const encontrarGratificacion6 = modalData.contratosHaberes.find(
            (e) => e.haber.haberesDt.id == 6
          );
          const encontrarGratificacion22 = modalData.contratosHaberes.find(
            (e) => e.haber.haberesDt.id == 22
          );
          if (encontrarGratificacion22 || encontrarGratificacion6) {
            setTieneGratificaciones(true);
          } else {
            setTieneGratificaciones(false);
          }
        }else {
        setTieneSemanaCorrida(modalData.semanaCorrida);
      }

      reset({
        version: parseInt(modalData.version),
        contratoAnexoId: modalData.id,
        estado: estado,
        nombreTrabajador: nombreTrabajador,
        rut: rutTrabajador,
        direccion: modalData.direccion,
        comuna: modalData.comuna,
        ciudad: modalData.ciudad,
        plazoContratoId: modalData.plazoContratoId,
        plazoContratoNombre: modalData.plazoContratoNombre,
        fechaIngreso: modalData.fechaIngreso,
        fechaIngresoLegal: modalData.fechaIngresoLegal,
        fechaTerminoContrato: modalData.fechaTerminoContratoBase,
        fechaAnexo: modalData.fechaAnexo,
        fechaTerminoAnexo: modalData.fechaTerminoAnexo,
        articulo22: articulo22,
        jornadaNombre: modalData.jornadaNombre,
        centroCostoNombre: modalData.centroCostoNombre,
        seccionNombre: modalData.seccionNombre,
        cargoNombre: modalData.cargoNombre,
        trabajoPesadoNombre: trabajoPesado,
        gratificacionNombre: modalData.gratificacionNombre,
        gratificacionTexto: modalData.gratificacionObservacion,
      });
    }
  }, [modalData]);

  async function cambiarVersionContrato(version) {
    toast.loading("Actualizando versión", estilosAlerta);
    try {
      const payload = {
        contratoId: contratoId,
        version: version,
      };

      // console.log("Payload", payload);
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/contratoAnexoByVersion`,
        payload,
        Authorization
      );
      console.log("response", response.data);
      setAfectoASemanaCorrida(response.data.semanaCorrida);

      const articulo22 = response.data.articulo22 == true ? "Si" : "No";

      const estado =
      response.data.estado === 1
        ? "Vigente"
        : response.data.estado === 2
        ? "Vencido"
        : response.data.estado === 3
        ? "Finiquitado"
        : null;

      const trabajoPesado =
        response.data.trabajoPesado == 0
          ? "No"
          : response.data.trabajoPesado == 1
          ? "Trabajo menos pesado 2%"
          : response.data.trabajoPesado == 2
          ? "Trabajo pesado 4%"
          : null;

      setHaberesAnexo(
        response.data.anexosHaberes
          ? response.data.anexosHaberes
          : response.data.contratosHaberes
      );

      const fechaIngreso = response.data.fechaIngreso
        ? `${response.data.fechaIngreso.split("-")[2]}-${
            response.data.fechaIngreso.split("-")[1]
          }-${response.data.fechaIngreso.split("-")[0]}`
        : "";

      const fechaIngresoLegal = response.data.fechaIngresoLegal
        ? `${response.data.fechaIngresoLegal.split("-")[2]}-${
            response.data.fechaIngresoLegal.split("-")[1]
          }-${response.data.fechaIngresoLegal.split("-")[0]}`
        : "";

      const fechaTerminoContratoOriginal = response.data.fechaTerminoContrato
        ? `${response.data.fechaTerminoContrato.split("-")[2]}-${
            response.data.fechaTerminoContrato.split("-")[1]
          }-${response.data.fechaTerminoContrato.split("-")[0]}`
        : "";

      const fechaAnexo = response.data.fechaAnexo
        ? `${response.data.fechaAnexo.split("-")[2]}-${
            response.data.fechaAnexo.split("-")[1]
          }-${response.data.fechaAnexo.split("-")[0]}`
        : "";

      const fechaTerminoAnexo = response.data.fechaTerminoAnexo
        ? `${response.data.fechaTerminoAnexo.split("-")[2]}-${
            response.data.fechaTerminoAnexo.split("-")[1]
          }-${response.data.fechaTerminoAnexo.split("-")[0]}`
        : "";

      reset({
        version: parseInt(version),
        contratoAnexoId: response.data.id,
        estado: estado,
        plazoContratoId: response.data.plazoContrato.id,
        plazoContratoNombre: response.data.plazoContrato.nombre,
        fechaIngreso: fechaIngreso,
        fechaIngresoLegal: fechaIngresoLegal,
        fechaTerminoContrato: fechaTerminoContratoOriginal,
        fechaAnexo: fechaAnexo,
        fechaTerminoAnexo: fechaTerminoAnexo,
        articulo22: articulo22,
        jornadaNombre: response.data.jornada.nombre,
        centroCostoNombre: response.data.centroCosto.nombre,
        seccionNombre: response.data.seccion.nombre,
        cargoNombre: response.data.cargo.nombre,
        trabajoPesadoId: response.data.trabajoPesado,
        trabajoPesadoNombre: trabajoPesado,
        gratificacionId: response.data.gratificacion.id,
        gratificacionNombre: response.data.gratificacion.nombre,
        gratificacionTexto: response.data.gratificacion.observacion,

        // nombreTrabajador: nombreTrabajador,
        // rut: rutTrabajador,
        // direccion: modalData.direccion,
        // comuna: modalData.comuna,
        // ciudad: modalData.ciudad,

      });

      toast.dismiss();
      toast.success("Versión actualizada con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error("Error al actualizar versión", estilosAlerta);
    }
  }

  useEffect(() => {
    return () => {
      reset({});
      setRowDataAnexo(false);
      setHaberesAnexo([])
    };
  }, []);

  const TABLA_HABERES_TRABAJADOR = [
    {
      Header: "Nombre",
      accessor: "haber.nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Categoría L.R.E.",
      accessor: "haber.haberesDt.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Imponible",
      accessor: "haber.haberesDt.imponible",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Tributable",
      accessor: "haber.haberesDt.tributable",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Constituye Remuneración",
      accessor: "haber.haberesDt.constRemuneracion",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value == "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Fijo o Variable",
      accessor: "valorFijo",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true) {
          return "Fijo";
        } else {
          return "Variable";
        }
      },
    },
    {
      Header: "Monto",
      accessor: "valor",
      Filter: ColumnFilter,
      Cell: ({ value, row }) => {
        if (parseInt(value) === -1 || row.original.valorVariable === true) {
          return "N/A";
        } else {
          return "$" + UtilsFunctions.integerFormatter(value);
        }
      },
    },
    {
      Header: "Cond. asistencia total",
      accessor: "haber.condicionalAsistenciaTotal",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Proporcional a dias trabajados",
      accessor: "haber.pagoProporcionalDiasTrabajados",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const loadingModal = haberesAnexo.length > 0;
  console.log("watch", watch())

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-screen my-6 mx-auto max-w-6xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                Ver Contrato/Anexo
              </h3>
            </div>

            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  {/* Primer Fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Versión
                      </label>

                      {/* <input
                        {...register("version")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      /> */}

                      <select
                      id="selectVersiones"
                      {...register("version", {
                        onChange: (e) => {
                          console.log("value", e.target.value)
                          cambiarVersionContrato(e.target.value);
                        },
                      })}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {opciones.map((opcion) => (
                        <option key={opcion} value={opcion}>
                          {opcion}
                        </option>
                      ))}
                    </select>
                    </div>

                    <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Empresa
                      </label>
                      <input
                        value={nombreEmpresa}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Estado
                      </label>
                      <input
                        {...register("estado")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  {/* Segunda Fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Nombre
                      </label>
                      <input
                        {...register("nombreTrabajador")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Rut
                      </label>
                      <input
                        {...register("rut")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  {/* Tercer Fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="direccion"
                      >
                        Dirección
                      </label>
                      <input
                        {...register("direccion")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        id="direccion"
                      />
                    </div>

                    <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="comuna"
                      >
                        Comuna
                      </label>
                      <input
                        {...register("comuna")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        id="comuna"
                      />
                    </div>

                    <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="ciudad"
                      >
                        Ciudad
                      </label>
                      <input
                        {...register("ciudad")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        id="ciudad"
                      />
                    </div>
                  </div>

                  {/* Cuarta fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full relative md:w-3/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Duración del Anexo
                      </label>
                      <input
                        {...register("plazoContratoNombre")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  {/* Quinta fila */}
                  {parseInt(watch("version")) == 1 && (
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de Ingreso
                        </label>
                        <input
                          disabled
                          {...register("fechaIngreso")}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="date"
                        />
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de Ingreso Legal
                        </label>
                        <input
                          {...register("fechaIngresoLegal")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="date"
                        />
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de término del contrato base
                        </label>
                        {parseInt(watch("plazoContratoId")) !== 2 ? (
                          <>
                            <input
                              {...register("fechaTerminoContrato")}
                              disabled
                              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                              type="date"
                            />
                          </>
                        ) : (
                          watch("plazoContratoId") == "2" && (
                            <input
                              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                              type="text"
                              disabled
                              value={"No aplica"}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {/* Sexta fila*/}
                  {parseInt(watch("version")) !== 1 && (
                    <>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                            Fecha del Anexo
                          </label>
                          <input
                            {...register("fechaAnexo")}
                            disabled
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="date"
                          />
                        </div>

                        <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                            Fecha de término del Anexo
                          </label>
                          {watch("plazoContratoId") === 1 ? (
                            <>
                              <input
                                disabled
                                {...register("fechaTerminoAnexo")}
                                className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type="date"
                              />
                            </>
                          ) : (
                            <input
                              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                              type="text"
                              disabled
                              value={"No aplica"}
                            />
                            // )
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {/* Septima fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Trabajador Artículo 22
                      </label>
                      <input
                        {...register("articulo22")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Jornada Laboral
                      </label>
                      {watch("articulo22") === "Si" ||
                      watch("articulo22") === true ? (
                        <input
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                          disabled
                          value={"No aplica"}
                        />
                      ) : (
                        <input
                          {...register("jornadaNombre")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      )}
                    </div>
                  </div>

                  {/* Octava fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Centro de Costo
                      </label>
                      <input
                        {...register("centroCostoNombre")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Sección/Área
                      </label>
                      <input
                        {...register("seccionNombre")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Cargo
                      </label>
                      <input
                        {...register("cargoNombre")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  {/* Novena fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="duracionContrato"
                      >
                        Trabajo Pesado
                      </label>
                      <input
                        {...register("trabajoPesadoNombre")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="rounded-lg">
                    <TablaHaberes
                      col={TABLA_HABERES_TRABAJADOR}
                      tableData={haberesAnexo}
                      botonEliminar={false}
                    />
                  </div>

                  {tieneGratificaciones && (
                    <div className="relative mt-5 flex-auto ">
                      <div className="w-full md:w-min mb-6 md:mb-0 ">
                        <label
                          className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                          for="flexCheckDefault"
                        >
                          Gratificación
                        </label>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-3/12 mb-6 md:mb-0">
                          <input
                            {...register("gratificacionNombre")}
                            disabled
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                          />
                        </div>

                        <div className="w-full md:w-8/12 mx-3 mb-6 md:mb-0 ">
                          <input
                            disabled
                            {...register("gratificacionTexto")}
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            id="grid-rut"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="relative mt-5 flex-auto ">
                    {!tieneSemanaCorrida ? (
                      <div className="my-4">
                        <label
                          className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                          for="flexCheckDefault"
                        >
                          Trabajador no afecto a semana corrida
                        </label>
                      </div>
                    ) : (
                      <div className="my-4 mx-6">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled={!tieneSemanaCorrida}
                          checked={afectoASemanaCorrida}
                          onClick={() =>
                            setAfectoASemanaCorrida(!afectoASemanaCorrida)
                          }
                        />
                        <label
                          className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold px-1"
                          for="flexCheckDefault"
                        >
                          Trabajador afecto a semana corrida
                        </label>
                      </div>
                    )}
                  </div>

                  {showModalEliminar ? (
                    <AdvertenciaEliminarContratoAnexo
                      data={getValues()}
                      onClose={onClose}
                      onCloseAlert={() => setShowModalEliminar(false)}
                    />
                  ) : null}
                </>
              )}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  reset();
                }}
              >
                Cerrar
              </button>
              <button
                type="button"
                className="hover:scale-105 bg-red-600 text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                onClick={() =>
                  // eliminarContratoAnexo();
                  setShowModalEliminar(true)
                }
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
