import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentrosCostosByEmpresaId,
  listaPeriodosHastaActual,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import SimpleTable from "../../components/SimpleTable";

export default function InformeCentroCostos() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      periodoAnyo: "",
      centroCostoId: 0,
      tipoHaberDescuento: "",
      nombreHaberDescuento: "",
      tipoInforme: "",
    },
  });

  const { refreshTable, setRefreshTable, activeMenu, screenSize } =
    useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const empresaId = localStorage.getItem("empresaId");
  const centroCostoId = localStorage.getItem("centroCostoId");

  const dispatch = useDispatch();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    try {
      getPeriodosCargados();
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch]);

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const [infoTabla, setInfoTabla] = useState([]);
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);

  async function getPeriodosCargados() {
    const infoMeses = await axiosPrivate(
      `${api_service}/api/v1/periodos/cargados`,
      Authorization
    );
    setInfoPeriodos(infoMeses.data);
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) =>
        UtilsFunctions.integerToMonth(month.mes)
      );
      setMesesData(transformedMonths);
    } else {
      setMesesData([]);
    }
  };

  async function onSubmit() {
    const data = getValues();
    try {
      const info = await axiosPrivate(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );

      if (watch("tipoInforme") === "TotalesCentroCosto") {
        const response = await axiosPrivate(
          `${api_service}/api/v1/liquidaciones/informe-centro-costo/periodo/${info.data.id}/empresa/${empresaId}/`,
          Authorization
        );
        if (!response.data.length > 0) {
          toast.error("No existen registros en este Periodo", estilosAlerta);
          setInfoTabla([]);
        } else {
          setInfoTabla(response.data);
          setRefreshTable(!refreshTable);
          toast.dismiss();
          toast.success("Tabla cargada con éxito", estilosAlerta);
        }
      } else if (watch("tipoInforme") === "TotalesTrabajador") {
        toast.dismiss();
        toast.loading("Exportando csv")
        const payload = {
          periodoId: info.data.id,
          empresaId: empresaId,
          centroCostoId: data.centroCostoId,
        };
        axiosPrivate({
          url: `${api_service}/api/v1/liquidaciones/informe/pago-mensual`,
          method: "POST",
          data: payload,
          maxBodyLength: Infinity,
          headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
          responseType: "blob", // importante
          onDownloadProgress: (progressEvent) => {
           Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Informe_Centro_Costo_${UtilsFunctions.periodoToText(data.numeroMes, data.periodoAnyo)}.csv`);
          document.body.appendChild(link);
          link.click();
          toast.dismiss();
          toast.success("Informe Centro Costo descargado con éxito", estilosAlerta);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss();
          toast.error(error.response.data.message
          ? error.response.data.message : "Error al descargar archivo", estilosAlerta);
        });  
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar tabla",
        estilosAlerta
      );
    }
  }

  const COLUMNS = [
    {
      Header: " ",
      columns: [
        {
          Header: "Centro Costo",
          accessor: "nombre",
          Filter: ColumnFilter,
        },
      ],
    },
    {
      Header: "Hombres",
      columns: [
        {
          Header: "N° Trabajadores",
          accessor: "numeroTrabajadoresHombres",
          Filter: ColumnFilter,
        },
        {
          Header: "Total Dias Trabajados",
          accessor: "totalDiasTrabajaHombres",
          Filter: ColumnFilter,
        },
        {
          Header: "T. Bruto",
          accessor: "totalBrutoHombres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
        {
          Header: "T. Imponible",
          accessor: "totalImponibleHombres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
        {
          Header: "T. No Imponible",
          accessor: "totalNoImponibleHombres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
        {
          Header: "A. Patronal",
          accessor: "aportePatronalHomres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
      ],
    },

    {
      Header: "Mujeres",
      columns: [
        {
          Header: "N° Trabajadores",
          accessor: "numeroTrajabadoresMujeres",
          Filter: ColumnFilter,
        },
        {
          Header: "Total Dias Trabajados",
          accessor: "totalDiasTrabajaMujeres",
          Filter: ColumnFilter,
        },
        {
          Header: "T. Bruto",
          accessor: "totalBrutoMujeres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
        {
          Header: "T. Imponible",
          accessor: "totalImponibleMujeres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
        {
          Header: "T. No Imponible",
          accessor: "totalNoImponibleMujeres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
        {
          Header: "A. Patronal",
          accessor: "aportePatronalMujeres",
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return "$" + UtilsFunctions.integerFormatter(value);
          },
        },
      ],
    },
  ];

  const headersCsv = [
    { name: "Centro Costo", key: "nombre" },
    { name: "Numero Trabajadores Hombres", key: "numeroTrabajadoresHombres" },
    { name: "Total Dias Trabajados Hombres", key: "totalDiasTrabajaHombres" },
    { name: "Total Bruto Hombres", key: "totalBrutoHombres" },
    { name: "Total Imponible Hombres", key: "totalImponibleHombres" },
    { name: "Total No Imponible Hombres", key: "totalNoImponibleHombres" },
    { name: "Aporte Patronal Hombres", key: "aportePatronalHomres" },
    { name: "Numero Trabajadores Mujeres", key: "numeroTrajabadoresMujeres" },
    { name: "Total Dias Trabajados Mujeres", key: "totalDiasTrabajaMujeres" },
    { name: "Total Bruto Mujeres", key: "totalBrutoMujeres" },
    { name: "Total Imponible Mujeres", key: "totalImponibleMujeres" },
    { name: "Total No Imponible Mujeres", key: "totalNoImponibleMujeres" },
    { name: "Aporte Patronal Mujeres", key: "aportePatronalMujeres" },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Informes</div>
      <div className="titulo-Pagina">Informe centro costos</div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-3 flex flex-wrap">
          <div className="w-full md:w-2/12 md:pr-3 md:mb-11 mb-9">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Tipo Informe
            </label>
            <select
              {...register("tipoInforme", {
                onChange: (e) => {
                  setValue("centroCostoId", 0);
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option selected hidden value="">
                Seleccione un informe
              </option>
              <option value="TotalesCentroCosto" key="1">
                Totales por Centro Costo
              </option>
              <option value="TotalesTrabajador" key="2">
                Totales por Trabajador
              </option>
            </select>
          </div>

          <div className="flex flex-wrap w-full md:min-w-[160px] md:max-w-[245px]">
            <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[150px] mr-3 md:mr-0 md:pr-3 mb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
              >
                Periodo
              </label>
              <select
                {...register("periodoAnyo", {
                  onChange: (e) => {
                    setValue("periodoMes", "");
                    handleYearChange(e.target.value);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Año
                </option>
                {anyosData.map((anyo, i) => (
                  <option value={anyo} key={i}>
                    {anyo}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoAnyo && "opacity-0 invisible"
                }`}
              >
                {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
              </span>
            </div>

            <div className="w-5/12 md:w-6/12 md:min-w-[142px] md:max-w-[200px] mt-6 md:pr-3 mb-3">
              <select
                {...register("periodoMes", {
                  onChange: (e) => {
                    setValue("numeroMes", e.target.selectedIndex);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                disabled={watch("periodoAnyo") === "" ? true : false}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Mes
                </option>
                {mesesData.map((mes, i) => (
                  <option value={mes} key={i}>
                    {mes}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoMes && "opacity-0 invisible"
                }`}
              >
                {errors.periodoMes ? errors.periodoMes.message : "hidden"}
              </span>
            </div>
          </div>

          {watch("tipoInforme") === "TotalesTrabajador" ? (
            <div className="w-7/12 md:w-4/12 md:min-w-[200px] md:max-w-[300px] mb-3 md:px-3">
              <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Centro de Costo
              </label>
              <select
                {...register("centroCostoId", {
                  onChange: (e) => {
                    const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                    setValue("centroCosto", centroCosto);
                  },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value={"0"}>Todos</option>
                {infoCCosto.map((ccosto, i) => (
                  <option value={ccosto.id} key={i}>
                    {ccosto.nombre}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          {watch("tipoInforme") !== "" ? (
            <div className="w-4/12 md:max-w-[150px] md:mt-6 md:ml-3 mb-6 md:mb-0">
              <button
                type="submit"
                className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400"
              >
                {watch("tipoInforme") === "TotalesCentroCosto"
                  ? "Buscar"
                  : watch("tipoInforme") === "TotalesTrabajador" && "Exportar"}
              </button>
            </div>
          ) : null}
        </div>
      </form>

      {infoTabla.length > 0 && (
        <>
          <div className="h-full md:mr-12 rounded-lg">
            <SimpleTable
              col={COLUMNS}
              tableData={infoTabla}
              showFilters={false}
              searchBar={false}
            />
          </div>
          <div className="w-full md:w-80 md:mt-6 mt-3">
            <button
              type="button"
              onClick={() => {
                UtilsFunctions.jsonToCSV3(
                  infoTabla,
                  headersCsv,
                  "Informe Centro Costos"
                );
              }}
              className="w-7/12 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
            >
              Exportar Tabla
            </button>
          </div>
        </>
      )}
    </div>
  );
}
