import React, { useEffect, useState } from "react";
import ModalSpinner from "../../ModalSpinner";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { set, useForm } from "react-hook-form";
import TablaModalAsignarDescuentos from "./TablaModalAsignarDescuentos";
import ColumnFilter from "../../ColumnFilter";
import {
  getDescuentosActivos,
  getHistorialDescuentosFijosTrabajador,
  limpiarInfoMenuEdicion,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { axiosPrivate } from "../../../common/axiosPrivate";
import toast from "react-hot-toast";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function ModalAsignarDescuento({
  modalData,
  isVisible,
  onClose,
}) {
  const {
    watch,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      descuentoId: "",
      tipoMonto: "0",
      unidadId: "",
    },
  });
  const { screenSize } = useStateContext();
  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };
  const dispatch = useDispatch();
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getDescuentosActivos()),
        dispatch(getHistorialDescuentosFijosTrabajador(modalData.id)),
      ]);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
    });
  }, [dispatch, modalData.id, refreshKey]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getDescuentosActivos"));
      dispatch(limpiarInfoMenuEdicion("getDescuentosFijosTrabajador"));
    };
  }, []);

  const listaDescuentosActivos = useSelector(
    (state) => state.getDescuentosActivos
  );
  const listaDescuentosTrabajador = useSelector(
    (state) => state.getDescuentosFijosTrabajador
  );

  const loadingModal = true;
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);

  const listaUnidadesValor = [
    {
      id: 0,
      nombre: "Pesos",
    },
    {
      id: 1,
      nombre: "UF",
    },
    {
      id: 2,
      nombre: "UTM",
    },
  ];

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const handleChange = (e) => {
    let value = e.target.value;

    value = value.replace(",", ".");

    // Eliminar caracteres no numéricos excepto el punto
    value = value.replace(/[^\d.]/g, "");

    const parts = value.split(".");
    if (parts.length > 2) {
      parts.splice(2); // Si hay más de un punto, eliminar lo que sobra
    }
    if (parts[0].length > 2) {
      parts[0] = parts[0].slice(0, 2); // Limitar a 2 enteros
    }
    if (parts[1]?.length > 2) {
      parts[1] = parts[1].slice(0, 2); // Limitar a 2 decimales
    }

    value = parts.join(".");
    setValue("montoDecimales", value ? `${value.replace(".", ",")}` : "");
  };

  const COLUMNAS_DESCUENTOS = [
    {
      Header: "Descuento Fijo",
      accessor: "nombreDescuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Unidad",
      accessor: "tipoMonto",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => {
        if (d.tipoMonto === "PESOS") {
          return "$" + UtilsFunctions.integerFormatter(d.valor);
        } else if (d.tipoMonto === "UF") {
          return d.valor + " UF";
        } else if (d.tipoMonto === "UTM") {
          return d.valor + " UTM";
        } else if (d.tipoMonto === "PORCENTAJE") {
          return (d.valor * 100).toFixed(2) + "%";
        } else {
          return d.valor;
        }
      },
      Filter: ColumnFilter,
    },
  ];

  // 0 es pesos, 1 uf y 2 es utm y 3 porcentaje 
  async function onSubmit(data) {
    console.log("data", data)
    let valorDescuento;

    if(data.unidadId === "0") {
      valorDescuento = data.montoPesos.replaceAll(".", "").replaceAll(",", ".").replaceAll("$", "");
    } else if(data.unidadId === "1" || data.unidadId === "2"){
      // es UF o UTM
      valorDescuento = data.montoDecimales.replaceAll(",", ".");
    } else if(data.unidadId === "3"){
      // es Porcentaje
      valorDescuento = data.montoPorcentaje.replaceAll(",", ".").replaceAll("%", "")/ 100;
    }

    const payload = {
      trabajadorId: modalData.id,
      descuentoId: data.descuentoId,
      tipoMonto: data.unidadId,
      valor: valorDescuento,
    };

    try {
      await axiosPrivate
        .post(
          `${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/fijo`,
          payload,
          Authorization
        )
        .then((res) => {
          toast.dismiss();
          toast.success("Descuento fijo creado con éxito", estilosAlerta);
          reset();
          setBotonGuardarHabilitado(true);
          setRefreshKey((oldKey) => oldKey + 1);
        })
        .catch((error) => {
          console.log("error", error)
          toast.dismiss();
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : "Error al crear Descuento fijo",
            estilosAlerta
          );
          setBotonGuardarHabilitado(true);
        });
    } catch (error) {
      console.log(error.response.data.message);
    }
  }

  async function eliminarDescuentoFijo(id) {
    await axiosPrivate
      .delete(`${REACT_APP_API_HERCULES_SERVICE}/api/v1/descuentos/fijo`, {
        data: {
          descuentoFijoId: id,
        },
        headers: {
          Authorization: `Bearer ${tokenUser}`,
          ClientId: `${token}`,
        },
      })
      .then((res) => {
        toast.dismiss();
        toast.success("Descuento fijo eliminado con éxito", estilosAlerta);
        setRefreshKey((oldKey) => oldKey + 1);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al eliminar Descuento fijo",
          estilosAlerta
        );
      });
  }

  if (!isVisible) {
    return null;
  }
  // console.log("watch", watch());

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-5xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Asignar Descuento</h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-3/12 md:pr-3 mb-6">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        value={UtilsFunctions.rutFormatter(modalData.rut, {
                          dots: true,
                        })}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-5/12 md:px-3 mb-6">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Trabajador
                      </label>
                      <input
                        disabled
                        value={
                          modalData.nombres +
                          " " +
                          modalData.apellido_Paterno +
                          " " +
                          modalData.apellido_Materno
                        }
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-5/12 mb-2 md:mb-0 md:pr-3">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Descuento
                        </label>
                        <select
                          {...register("descuentoId", {
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                            validacionTextInput,
                            onChange: (e) => {
                              const descuento =
                                listaDescuentosActivos[
                                  e.target.selectedIndex - 1
                                ];
                              setValue("descuento", descuento);
                              setValue("esPorcentaje", descuento.esPorcentaje);
                              setValue("valor", "");
                              if (descuento.esPorcentaje) {
                                setValue("tipoMonto", "3");
                                setValue("unidadId", "3");
                              } else {
                                setValue("tipoMonto", "0");
                                setValue("unidadId", "");
                              }
                            },
                          })}
                          name="descuentoId"
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option hidden value="">
                            Seleccionar Descuento
                          </option>
                          {listaDescuentosActivos?.map((haber, i) => (
                            <option key={i} value={haber.id}>
                              {haber.nombre}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.descuentoId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.descuentoId
                            ? errors.descuentoId.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="w-6/12 md:w-3/12 md:px-3">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Unidad
                        </label>
                        <select
                          {...register("unidadId", {
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                            onChange: () => {
                              setValue("montoDecimales", "")
                              setValue("montoPesos", "")
                            }
                          })}
                          disabled={watch("descuentoId") === ""}
                          name="unidadId"
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        >
                          {watch("descuentoId") === "" && (
                            <option hidden value="">
                              Seleccione Descuento
                            </option>
                          )}
                          {watch("esPorcentaje") === true ||
                          watch("esPorcentaje") === "true" ? (
                            <option key={4} value={"3"}>
                              Porcentaje
                            </option>
                          ) : (
                            <>
                              <option hidden value="">
                                Seleccionar Unidad
                              </option>
                              {listaUnidadesValor.map((haber, i) => (
                                <option key={i} value={haber.id}>
                                  {haber.nombre}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.unidadId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.unidadId ? errors.unidadId.message : "hidden"}
                        </span>
                      </div>

                      <div className="w-6/12 md:w-2/12 pl-3 md:pl-0 md:px-3">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Monto
                        </label>

                        {(watch("descuentoId") === "" ||
                          watch("unidadId") === "") && (
                          <input
                            disabled
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                            placeholder="Monto"
                          />
                        )}

                        {(watch("esPorcentaje") === true ||
                          watch("esPorcentaje") === "true") &&
                          watch("unidadId") === "3" && (
                            <>
                              <input
                                placeholder="Monto"
                                {...register("montoPorcentaje", {
                                  validate: (value) => {
                                    if (
                                      value === "" ||
                                      value === "%" ||
                                      parseInt(value) < 0 ||
                                      parseInt(value) > 100
                                    ) {
                                      return "Entre 0 y 100%";
                                    }
                                    return true;
                                  },
                                  onChange: () => {
                                    setValue(
                                      "montoPorcentaje",
                                      getValues("montoPorcentaje")?.replace(
                                        /[^\d.,]/g,
                                        ""
                                      ) + "%"
                                    );
                                  },
                                })}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                              />
                              <span
                                className={`text-red-500 text-xs italic ${
                                  !errors.montoPorcentaje &&
                                  "opacity-0 invisible"
                                }`}
                              >
                                {errors.montoPorcentaje
                                  ? errors.montoPorcentaje.message
                                  : "hidden"}
                              </span>
                            </>
                          )}

                        {(watch("esPorcentaje") === false ||
                          watch("esPorcentaje") === "false") &&
                          watch("unidadId") === "0" && (
                            <>
                              <input
                                placeholder="Monto"
                                {...register("montoPesos", {
                                  validate: (value) => {
                                    if (
                                      value === "" ||
                                      value === "$" ||
                                      value === "$0"
                                    ) {
                                      return "Mayor a 0";
                                    }
                                    return true;
                                  },
                                  onChange: () => {
                                    setValue(
                                      "montoPesos",
                                      "$" +
                                        formatter.format(
                                          getValues("montoPesos")?.replace(
                                            /\D/g,
                                            ""
                                          )
                                        )
                                    );
                                  },
                                })}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                              />
                              <span
                                className={`text-red-500 text-xs italic ${
                                  !errors.montoPesos && "opacity-0 invisible"
                                }`}
                              >
                                {errors.montoPesos
                                  ? errors.montoPesos.message
                                  : "hidden"}
                              </span>
                            </>
                          )}

                        {(watch("esPorcentaje") === false ||
                          watch("esPorcentaje") === "false") &&
                          (watch("unidadId") === "1" ||
                            watch("unidadId") === "2") && (
                            <>
                              <input
                                placeholder="Monto UF UTM"
                                {...register("montoDecimales", {
                                  validate: (value) => {
                                    if (
                                      value === "" ||
                                      value === "$" ||
                                      value === "$0"
                                    ) {
                                      return "Mayor a 0";
                                    }
                                    return true;
                                  },
                                  onChange: (e) => handleChange(e),
                                  // onChange: () => {
                                  //   const value = getValues("montoPesos")?.replace(/[^\d,]/g, '').replace(',', '.');
                                  //   const numberValue = parseFloat(value);
                                  //   if (!isNaN(numberValue)) {
                                  //     setValue(
                                  //       "montoPesos",
                                  //       "$" + formatter.format(numberValue)
                                  //     );
                                  //   } else {
                                  //     setValue("montoPesos", "$");
                                  //   }
                                  // },
                                })}
                                value={watch("montoDecimales") || ""}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                              />
                              <span
                                className={`text-red-500 text-xs italic ${
                                  !errors.montoDecimales &&
                                  "opacity-0 invisible"
                                }`}
                              >
                                {errors.montoDecimales
                                  ? errors.montoDecimales.message
                                  : "hidden"}
                              </span>
                            </>
                          )}
                      </div>

                      <div className="mt-3 md:mt-6 mb-3 md:mb-0 md:pl-3">
                        <button
                          disabled={!botonGuardarHabilitado}
                          className={` ${
                            !botonGuardarHabilitado
                              ? "bg-gray-500"
                              : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                          }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                          type="submit"
                        >
                          Agregar
                        </button>
                      </div>
                    </div>
                  </form>

                  <TablaModalAsignarDescuentos
                    col={COLUMNAS_DESCUENTOS}
                    tableData={listaDescuentosTrabajador}
                    botonEliminarDescuento={true}
                    eliminarDescuentoFijo={(id) => {
                      eliminarDescuentoFijo(id);
                    }}
                  />
                </>
              )}
            </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  reset();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
