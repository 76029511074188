import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch } from "react-redux";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";

export default function MantenedorEditarCentroCosto({
  isVisible,
  onClose,
  modalData,
  listaEmpresas,
  listaBancos,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [habilitado, setHabilitado] = useState(undefined);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Campo obligatorio" },
  };

  useEffect(() => {
    if (modalData) {
      reset({
        id: modalData.id,
        nombreCentroCosto: modalData.nombre,
        descripcionCentroCosto: modalData.descripcion,
        codigoPrevired: modalData.codigoPrevired,
        bancoId: modalData.centrosCostosHasBancos[0].banco.id,
        empresaId: modalData.empresa.id,
        direccionCompleta: modalData.direccionCompleta ? modalData.direccionCompleta : "",
        comuna: modalData.comuna ? modalData.comuna : "",
      });
      setHabilitado(modalData.activo);
    }
  }, [modalData]);

  async function onSubmit(data) {
    setBotonGuardarHabilitado(false)
    toast.loading("Editando Centro de Costo", estilosAlerta)
    try {
      const requestBody = {
        id: data.id,
        nombre: data.nombreCentroCosto,
        descripcion: data.descripcionCentroCosto,
        codigoPrevired: data.codigoPrevired ? data.codigoPrevired : "",
        direccionCompleta: data.direccionCompleta ? data.direccionCompleta : "",
        comuna: data.comuna ? data.comuna : "",
        bancoId: data.bancoId,
        empresaId: data.empresaId,
        activo: habilitado,
      };
      const response = await axiosPrivate.put(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/centrodecosto`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Centro de Costo editado con éxito", estilosAlerta);
        onClose();
        reset();
        setRefreshTable(!refreshTable);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al editar Centro de Costo", estilosAlerta);
      setBotonGuardarHabilitado(true)
    }
  }

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Editar Centro de Costo</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {/* Primera fila modal */}
                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombreCentroCosto", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.nombreCentroCosto && "opacity-0 invisible"
                    }`}
                  >
                    {errors.nombreCentroCosto
                      ? errors.nombreCentroCosto.message
                      : "hidden"}
                  </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Descripción
                    </label>
                    <input
                      {...register(
                        "descripcionCentroCosto",
                        validacionTextInput
                      )}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.descripcionCentroCosto && "opacity-0 invisible"
                    }`}
                  >
                    {errors.descripcionCentroCosto
                      ? errors.descripcionCentroCosto.message
                      : "hidden"}
                  </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Código Previred
                    </label>
                    <input
                      {...register("codigoPrevired")}
                      maxLength="20"
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.codigoPrevired && "opacity-0 invisible"
                    }`}
                  >
                    {errors.codigoPrevired
                      ? errors.codigoPrevired.message
                      : "hidden"}
                  </span>
                  </div>

                  <div className="div-Inputs-EditarHaber mb-11">
                  <label
                    className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-nombre-fantasia"
                  >
                    Dirección (Opcional)
                  </label>
                  <input
                    {...register("direccionCompleta")}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                  />
                </div>

                <div className="div-Inputs-EditarHaber mb-11">
                  <label
                    className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-nombre-fantasia"
                  >
                    Comuna (Opcional)
                  </label>
                  <input
                    {...register("comuna")}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                  />
                </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Empresa
                    </label>
                    <select
                      {...register("empresaId", {
                        required: "Debe seleccionar una Empresa",
                        onChange: (e) => {
                          const empresa =
                            listaEmpresas[e.target.selectedIndex - 1];
                          setValue("empresa", empresa);
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar Empresa
                      </option>
                      {listaEmpresas.map((empresa, i) => (
                        <option value={empresa.id} key={i}>
                          {empresa.nombreFantasia}
                        </option>
                      ))}
                    </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.empresaId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.empresaId ? errors.empresaId.message : "hidden"}
                  </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Banco asociado a pagos
                    </label>
                    <select
                      {...register("bancoId", {
                        required: "Debe seleccionar un Banco",
                        onChange: (e) => {
                          const banco = listaBancos[e.target.selectedIndex - 1];
                          setValue("banco", banco);
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar Banco
                      </option>
                      {listaBancos.map((banco, i) => (
                        <option value={banco.id} key={i}>
                          {banco.nombre}
                        </option>
                      ))}
                    </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.bancoId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.bancoId ? errors.bancoId.message : "hidden"}
                  </span>
                  </div>

                  <div className="flex flex-wrap">
                    <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                      Activo
                    </span>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={habilitado}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setHabilitado(!habilitado);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                      ></div>
                    </label>
                  </div>

                  <div className="div-Inputs-EditarHaber mt-7">
                  <p className="text-justify font-semibold dark:text-white text-hercules-light-font-secondary">
                      El centro de costo es la unidad a la que se le imputarán
                      los gastos; esta puede ser una sucursal de su empresa.
                      Recuerde que cada centro de costo puede subdividirse en
                      secciones/áreas. Seleccione la empresa a la que se
                      asignará el centro de costo, y el banco con el que este
                      centro de costos pagará nómina.
                    </p>
                  </div>

              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
