import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  movimientosTrabajadorPeriodo,
  showLoader,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import TablaMovTrabajador from "../../components/Movimientos/MovimientosTrabajadores/MovimientosTrabTable";
import TablaImportarHsExtraHsDescuento from "../../components/Movimientos/MovimientosTrabajadores/TablaImportarHsExtraHsDescuento";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { parse } from "papaparse";
import { axiosPrivate } from "../../common/axiosPrivate";
import ModalErroresCargaMasiva from "../../components/ModalErroresCargaMasiva";
import SimpleTable from "../../components/SimpleTable";
import RutColumnFilter from "../../components/RutColumnFilter";
import GuiaImportacionPlantillas from "../../components/Movimientos/GuiaImportacionPlantillas";

export default function NuevoMovimientosTrabajador() {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { refreshTable, setRefreshTable, activeMenu, screenSize } =
    useStateContext();
  const dispatch = useDispatch();
  const periodoId = localStorage.getItem("periodoId");
  const empresaId = localStorage.getItem("empresaId");
  const [arrayHoras, setArrayHoras] = useState([]);
  const [showModalErrores, setShowModalErrores] = useState(false);
  const [erroresCarga, setErroresCarga] = useState([]);
  const [showModalGuiaImportacion, setShowModalGuiaImportacion] =
    useState(false);

  const validacion = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(movimientosTrabajadorPeriodo(periodoId, empresaId)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const listaMovimientosTrabPeriodo = useSelector(
    (state) => state.movimientosTrabajadorPeriodo
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("movimientosTrabajadorPeriodo"));
    };
  }, []);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const csvToJson2 = (csv) => {
    parse(csv, {
      delimiter: ";",
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (watch("tipoCalculo") === "2") {
          try {
            const headersRequeridos = [
              "RUT",
              "ID Contrato",
              "ID Anexo",
              "Horas Extra",
              "Horas Descuento",
              "Horas Domingo",
              "Horas Extra Domingo",
            ];
            UtilsFunctions.validarColumnasPlantilla(
              result.data,
              headersRequeridos,
              "Plantilla de Movimientos"
            );

                      // Ajustar las horas vacías a "0"
          const dataAjustada = result.data.map((item) => ({
            ...item,
            "Horas Extra": item["Horas Extra"] === "" ? "0" : item["Horas Extra"],
            "Horas Descuento": item["Horas Descuento"] === "" ? "0" : item["Horas Descuento"],
            "Horas Domingo": item["Horas Domingo"] === "" ? "0" : item["Horas Domingo"],
            "Horas Extra Domingo": item["Horas Extra Domingo"] === "" ? "0" : item["Horas Extra Domingo"],
          }));

            console.log("dataAjustada", dataAjustada)
            setArrayHoras(dataAjustada);
            toast.dismiss();
            toast.success("Datos importados con éxito", estilosAlerta);
          } catch (error) {
            console.log("error", error);
            toast.dismiss();
            toast.error(error.message);
          }
        }
      },
    });
  };

  const fileReader = new FileReader();

  const [file, setFile] = useState();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  async function importarPlantillaHoras() {
    if (!file) {
      toast.dismiss();
      toast.error("Primero debe subir una plantilla", estilosAlerta);
      return null;
    }

    try {
      toast.loading("Importando datos", estilosAlerta);
      fileReader.onload = function (event) {
        const text = event.target.result;
        console.log("Text", text)
        csvToJson2(text);
      };

      fileReader.readAsText(file);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al importar datos", estilosAlerta);
      console.log("error", error);
    }
  }

  async function guardarCargaMasiva() {
    const payload = arrayHoras.map((e) => {
      return {
        rutTrabajador: e["RUT"].replaceAll(".", ""),
        contratoId: e["ID Contrato"],
        anexoContratoId: e["ID Anexo"] === "0" ? null : e["ID Anexo"],
        horasExtra: parseFloat(e["Horas Extra"].replace(",", ".")),
        horasDescuento: parseFloat(e["Horas Descuento"].replace(",", ".")),
        horasDomingo: parseFloat(e["Horas Domingo"].replace(",", ".")),
        horasExtraDomingo: parseFloat(
          e["Horas Extra Domingo"].replace(",", ".")
        ),
      };
    });

    try {
      toast.loading("Guardando datos", estilosAlerta);
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/haberes/horas-extras-descuento/masivo/periodo/${periodoId}`,
        payload,
        Authorization
      );

      const errores = info.data;
      if (errores.length > 0) {
        toast.dismiss();
        toast.error(
          "Algunos trabajadores presentan errores en la carga masiva"
        );
        setErroresCarga(errores);
        setShowModalErrores(true);
      } else {
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Guardado con exito");
      }
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        "Hubo un error al guardar esta plantilla, por favor revise que los datos sean correctos",
        estilosAlerta
      );
    }
  }

  const COLUMNAS_MOV_TRABAJADORES = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Centro Costo",
      accessor: "nombreCentroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "Versión Contrato",
      accessor: "version",
      Filter: ColumnFilter,
    },
    {
      Header: "D. Trabajados",
      accessor: "diasTrabajados",
      Filter: ColumnFilter,
    },
    {
      Header: "H. extra",
      accessor: "horasExtra",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
    {
      Header: "H. descuento",
      accessor: "horasDescuento",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
  ];

  const COLUMNAS_PLANTILLA_IMPORTADA = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d["RUT"], { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Versión Contrato",
      accessor: "Version",
      Filter: ColumnFilter,
    },
    {
      Header: "Horas Extra",
      accessor: "Horas Extra",
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Horas Descuento",
      accessor: "Horas Descuento",
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Horas Domingo",
      accessor: "Horas Domingo",
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Horas Extra Domingo",
      accessor: "Horas Extra Domingo",
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_DESCARGAR_PLANTILLA = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Versión contrato",
      accessor: "version",
      Filter: ColumnFilter,
    },
    {
      Header: "H. extra",
      accessor: "horasExtra",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
    {
      Header: "H. descuento",
      accessor: "horasDescuento",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
    {
      Header: "Horas Extra Domingo",
      accessor: "horasExtrasDomingo",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
    {
      Header: "Horas Domingo",
      accessor: "horasDomingo",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
  ];

  const headersCsv = [
    { name: "RUT", key: "rut" },
    { name: "Apellido Paterno", key: "apellidoPaterno" },
    { name: "Apellido Materno", key: "apellidoMaterno" },
    { name: "Nombre", key: "nombres" },
    { name: "Centro Costo", key: "nombreCentroCosto" },
    { name: "Version Contrato", key: "version" },
    { name: "Dias Trabajados", key: "diasTrabajados" },
    { name: "Horas Extra", key: "horasExtra" },
    { name: "Horas Descuento", key: "horasDescuento" },
  ];

  const texto = (
    <>
      <p className="pb-3">
        Esta plantilla tiene las siguientes columnas, por favor verifique que
        los datos ingresados tengan el formato requerido:
      </p>
      <ul>
        <li className="py-2">
          <b>* RUT: </b>En formato nn.nnn.nnn-d, vendrá por defecto cuando se
          selecciona al trabajador (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Trabajador: </b>Nombre del trabajador. Vendrá por defecto cuando
          se selecciona al trabajador.
        </li>
        <li className="py-2">
          <b>* ID Contrato: </b>Número natural. Vendrá por defecto cuando se
          selecciona al trabajador. Para distinguir los contratos y anexos, ver
          el campo “Version” (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* ID Anexo: </b>Número natural. Vendrá por defecto cuando se
          selecciona al trabajador. Para distinguir los contratos y anexos, ver
          el campo “Version” (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Version: </b>Número natural. Vendrá por defecto cuando se
          selecciona al trabajador. Se distinguirá la última versión como el
          número de mayor tamaño con respecto al mismo trabajador. Siendo así,
          el anexo o contrato que termina primero, el que tenga menor número de
          versión, y el más actual aquel que tenga mayor número de versión
          (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Hora Extra: </b>Entero con decimales. Se deberá convertir la hora
          extra en números decimales, (ejemplo: 1:30 min, serán 1,5)
          (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Hora Descuento: </b>Entero con decimales. Se deberá convertir la
          hora de descuento en números decimales, (ejemplo: 1:20 min, serán
          1,333) (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Hora Extra Domingo: </b>Entero con decimales. Se deberá convertir
          la hora extra de día domingo en números decimales, (ejemplo: 2:15 min,
          serán 2,25) (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Horas Domingo: </b>Entero con decimales. Se deberá convertir la
          hora de día domingo en números decimales, (ejemplo: 3:01 min, serán
          3,02) (OBLIGATORIO).
        </li>
      </ul>
    </>
  );

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Movimientos de Trabajadores</div>

      <div>
        <p className="mt-3 dark:text-white text-hercules-light-font-secondary">
          Ante cualquier duda o conflicto al importar plantillas, le
          recomendamos revisar{" "}
          <u>
            <button
              onClick={() => setShowModalGuiaImportacion(true)}
              className="hover:cursor-pointer font-medium underline"
            >
              esta guia
            </button>
          </u>
        </p>
      </div>

      <div className="rounded-lg pt-5 ">
        <div className="flex max-w-2xl">
          {/* Boton para ver movimientos */}
          <button
            className={`w-7/12 md:w-6/12 rounded-t text-center py-3 md:font-semibold text-xs md:text-sm lg:text-base dark:border-x dark:border-dark-bg-200 ${
              activeTab === 1
                ? "bg-white dark:bg-hercules-dark-bg-100  dark:text-gray-50 text-hercules-light-font-secondary border-t border-x border-gray-300 dark:border-dark-bg-200"
                : "bg-gray-600 text-white opacity-70"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Mov. de Trabajadores
          </button>

          {/* Boton para importar plantilla */}
          <button
            className={` w-5/12 rounded-t text-center py-3 ml-1 md:font-semibold text-xs md:text-sm lg:text-base ${
              activeTab === 2
                ? "bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-t border-x border-gray-300 dark:border-dark-bg-200"
                : "bg-gray-600 text-white opacity-70"
            }`}
            onClick={() => handleTabClick(2)}
          >
            Importar Plantilla
          </button>

          {/* Boton para descargar plantilla */}
          <button
            className={` w-5/12 rounded-t text-center py-3 ml-1 md:font-semibold text-xs md:text-sm lg:text-base ${
              activeTab === 3
                ? "bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-t border-x border-gray-300 dark:border-dark-bg-200"
                : "bg-gray-600 text-white opacity-70"
            }`}
            onClick={() => handleTabClick(3)}
          >
            Descargar Plantilla
          </button>
        </div>

        <div>
          <div className={`${activeTab === 1 ? "block " : "hidden"} `}>
            <div className="h-full rounded-b-lg md:mr-12">
              <TablaMovTrabajador
                col={COLUMNAS_MOV_TRABAJADORES}
                tableData={listaMovimientosTrabPeriodo}
                botonEditar={true}
                botonHoras={true}
                exportCsv={() =>
                  UtilsFunctions.jsonToCSV3(
                    listaMovimientosTrabPeriodo,
                    headersCsv,
                    "Movimientos de Trabajdores"
                  )
                }
              />
            </div>
          </div>

          <div className={`${activeTab === 2 ? "block " : "hidden"} `}>
            {arrayHoras.length ? (
              <div className="h-full rounded-b-lg md:mr-12">
                <SimpleTable
                  col={COLUMNAS_PLANTILLA_IMPORTADA}
                  tableData={arrayHoras}
                  exportCsvButton={false}
                  borders={false}
                />

                <div className="mt-3 flex flex-row">
                  <div className="mb-6 md:mb-0 mr-3">
                    <button
                      onClick={() => setArrayHoras([])}
                      className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                    >
                      Limpiar tabla
                    </button>
                  </div>

                  <div className=" pl-3 md:pl-0">
                    <button
                      onClick={() => guardarCargaMasiva()}
                      className="mr-1 mb-1 hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="h-608 bg-white dark:bg-hercules-dark-bg-100 border-gray-300 dark:border-dark-bg-200 md:mr-12 rounded-b-md rounded-tr-md border-y border-x">
                  <h4 className="text-xl md:mr-20 md:ml-11 md:pt-11 md:pb-20 dark:text-gray-50 text-hercules-light-font-secondary">
                    Aún no ha importado una plantilla de Haberes/Descuentos o
                    puede que haya intentado cargar una plantilla con errores.
                    Por favor cargue una plantilla para luego importarla.
                  </h4>
                </div>

                {/* Selector de tipo plantilla */}
                <form onSubmit={handleSubmit(importarPlantillaHoras)}>
                  <div className="md:flex md:flex-wrap pt-4 md:pt-5">
                    <div className="md:w-4/12 pt-5 md:pt-0 md:mr-3">
                      <label className="dark:text-white text-hercules-light-font-secondary">
                        Cargar archivo:
                      </label>

                      <input
                        type={"file"}
                        id={"xlsxFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      />
                    </div>

                    <div className="w-full pt-5 md:pt-0 md:w-2/12 md:mx-3">
                      <label className=" dark:text-white text-hercules-light-font-secondary">
                        Tipo de calculo
                      </label>

                      <select
                        {...register("tipoCalculo", validacion)}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-4 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value={""} hidden>
                          Seleccionar
                        </option>
                        <option value={"2"}>Reemplazar</option>
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.tipoCalculo && "opacity-0 invisible"
                        }`}
                      >
                        {errors.tipoCalculo
                          ? errors.tipoCalculo.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="w-full pt-1 md:pt-6 md:w-2/12 md:px-3 mb-6 md:mb-0">
                      <button
                        type="submit"
                        className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                      >
                        Importar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>

          <div className={`${activeTab === 3 ? "block " : "hidden"} `}>
            <div className="h-full rounded-b-lg md:mr-12">
              <TablaImportarHsExtraHsDescuento
                col={COLUMNAS_DESCARGAR_PLANTILLA}
                tableData={listaMovimientosTrabPeriodo}
              />
            </div>
          </div>
        </div>
      </div>
      {showModalErrores && (
        <ModalErroresCargaMasiva
          errores={erroresCarga}
          isVisible={showModalErrores}
          onClose={() => setShowModalErrores(false)}
          titulo={"Errores en la carga masiva de Horas Extras/Descuentos"}
          mensaje={
            "Los siguientes trabajadores presentan errores en la carga masiva de horas extras/descuentos, por favor corrijalos e intente nuevamente."
          }
        />
      )}

      {showModalGuiaImportacion && (
        <GuiaImportacionPlantillas
          nombrePlantilla="Horas Extras/Descuento"
          body={texto}
          isVisible={showModalGuiaImportacion}
          onClose={() => setShowModalGuiaImportacion(false)}
        />
      )}
    </div>
  );
}
