import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  datosTrabajador,
  getEventosActivos,
  getPaises,
  getTrabajadores,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  ColumnFilter,
  ColumnFilterBloqueadoActivo,
  NuevoTrabajadorMantenedor,
  EditarTrabajadorMantenedor,
} from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import EditarEventosTrabajador from "../../components/Edicion/MantenedorTrabajadores/Eventos/EditarEventosTrabajador";
import RutColumnFilter from "../../components/RutColumnFilter";

const Trabajadores = () => {
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getPaises()),
        dispatch(getTrabajadores()),
        dispatch(datosTrabajador()),
        dispatch(getEventosActivos()),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("paises"))
      dispatch(limpiarInfoMenuEdicion("regiones"))
      dispatch(limpiarInfoMenuEdicion("trabajadores"))
      dispatch(limpiarInfoMenuEdicion("getSindicatosActivos"))
      dispatch(limpiarInfoMenuEdicion("datosTrabajador"))
      dispatch(limpiarInfoMenuEdicion("getEventosActivos"))
    }
  },[])

  const trabajadoresData = useSelector((state) => state.trabajadores);
  const eventosActivos = useSelector((state) => state.getEventosActivos);
  const [showModalCrearTrabajador, setShowModalCrearTrabajador] =
    useState(false);
  const [showModalEditarTrabajador, setShowModalEditarTrabajador] =
    useState(false);
  const [editarTrabajadorModalData, setEditarTrabajadorModalData] =
    useState(null);
    const [showModalEditarEventoTrabajador, setShowModalEditarEventoTrabajador] =
    useState(false);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: (d) =>
        `${d.apellido_Paterno} ${d.apellido_Materno} ${d.nombres}`,
      Filter: ColumnFilter,
    },
    {
      Header: "Afp",
      accessor: "afpNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Salud",
      accessor: "saludNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: "activo",
      Filter: ColumnFilterBloqueadoActivo,
      Cell: ({ value }) => {
        return value ? "Activo" : "Bloqueado";
      },
    },
  ];

  const headersCsv = [
    { name: "Rut", key: "rut" },
    { name: "Nombre", key: "nombres" },
    { name: "Apellido Paterno", key: "apellido_Paterno" },
    { name: "Apellido Materno", key: "apellido_Materno" },
    { name: "Salud", key: "saludNombre" },
    { name: "Afp", key: "afpNombre" },
    { name: "Estado", key: "activo" },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Trabajadores
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={trabajadoresData || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setShowModalEditarTrabajador(true);
                setEditarTrabajadorModalData(data);
              },
              active: true,
            }}
            secondaryButton={{
              type:"eventos",
              onClickFunc: (data) => {
                setShowModalEditarEventoTrabajador(true);
                setEditarTrabajadorModalData(data);
              },
              active: true,              
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV(
                trabajadoresData,
                headersCsv,
                "Trabajadores.csv"
              )
            }
          />

        <button
          onClick={() => {
            setShowModalCrearTrabajador(true);
          }}
          disabled={isLoading}
          className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
        >
          Nuevo Trabajador
        </button>
      </div>
      {showModalCrearTrabajador &&
        <NuevoTrabajadorMantenedor
          isVisible={showModalCrearTrabajador}
          onClose={() => setShowModalCrearTrabajador(false)}
        />
      }
      {showModalEditarTrabajador &&
        <EditarTrabajadorMantenedor
          isVisible={showModalEditarTrabajador}
          onClose={() => setShowModalEditarTrabajador(false)}
          infoTrabajadorAEditar={editarTrabajadorModalData}
        />
      }
      {showModalEditarEventoTrabajador &&
        <EditarEventosTrabajador
          isVisible={showModalEditarEventoTrabajador}
          onClose={() => setShowModalEditarEventoTrabajador(false)}
          modalData={editarTrabajadorModalData}
          eventosActivos={eventosActivos}
        />
      }
    </div>
  );
};

export default Trabajadores;
