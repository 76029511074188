import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table' 

const RutColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    const [value, setValue] = useState(filterValue)
    const onChange = useAsyncDebounce(value => {
        setFilter(value.replaceAll(".", "") || undefined)
    }, 400)

  return (
    <>
        <input className='w-full h-8 bg-white rounded font-normal border-1' value={value || ""} placeholder=" Filtrar..." onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
        }} />
    </>
  )
}

export default RutColumnFilter