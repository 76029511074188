import React from "react";
import { useStateContext } from "../contexts/ContextProvider";

export default function Page404({}) {
  const { activeMenu, screenSize } = useStateContext();

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Error 404</div>
      <div className="titulo-Pagina">Parece que esta página no existe o ha sido modificada</div>
    </div>
  );
}
